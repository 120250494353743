import React from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

const Auth0ProviderWithHistory = ({ children }) => {
  const navigate = useNavigate();

  const onRedirectCallback = (appState) => {
    navigate(window.location.pathname || appState?.returnTo);
  };

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      onRedirectCallback={onRedirectCallback}
      audience={process.env.REACT_APP_AUTH0_AUDIENCE}
      authorizationParams={{
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        redirect_uri: window.location.origin,
      }}
      scope="openid profile email read:current_user"
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
