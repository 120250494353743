import styled from "styled-components";
import { NavLink, Link } from "react-router-dom";
import { mobile } from "../styles/responsive";

export const LogoStyles = {
  maxWidth: "170px",
  width: "100%",
  height: "110px",
  marginLeft: "45px",
  marginRight: "55px",
};

export const ActionContainer = styled.div`
  text-align: center;
  align-items: center;
  justify-content: center;
  padding-top: 25px;
  padding-bottom: 24px;
  border-top: ${(props) => `2px solid ${props.theme.offwhite}`};
`;

export const ModalActionRow = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: row;
`;

export const ModalContentContainer = styled.div`
  padding: 24px 32px 0px;
  display: ${(props) => props.centered && "flex"};
  align-items: ${(props) => props.centered && "center"};
  flex-direction: ${(props) => props.centered && "column"};
  overflow: auto;
  max-height: ${(props) => (props.maxHeight ? props.maxHeight : "550px")};
  height: 100%;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const selectStyles = {
  control: (baseStyles) => ({
    ...baseStyles,
    border: "1px solid #fff",
    boxShadow: "1px 3px 14px -6px rgba(0, 0, 0, 0.25)",
    height: "40px",
    borderRadius: "8px",
  }),
  singleValue: (baseStyles) => ({
    ...baseStyles,
    fontSize: "14px",
    color: "#4E5357",
    paddingLeft: "10px",
  }),
  input: (baseStyles) => ({
    ...baseStyles,
    fontSize: "14px",
    color: "#4E5357",
    paddingLeft: "10px",
  }),
  placeholder: (baseStyles) => ({
    ...baseStyles,
    fontSize: "14px",
    color: "#B6B6B6",
    paddingLeft: "10px",
  }),
};

export const Line = styled.div`
  width: 30px;
  border-bottom: ${(props) => `3px dotted ${props.theme.toolTipBackground}`};
  margin: ${(props) => (props.haserror ? "45px 0 auto 0" : "auto 0")};
`;

export const StyledTooltip = styled.div`
  margin: ${(props) => (props.haserror ? "26px 0 auto 0" : "auto 0")};
  background-color: ${(props) => props.theme.toolTipBackground};
  padding: 12px;
  border-radius: 8px;
  font-size: 14px;
  max-width: 400px;
  width: ${(props) => (props.small ? "82.5%" : "100%")}
  color: ${(props) => props.theme.offblack};
`;

export const CtaButton = styled(NavLink)`
  background: linear-gradient(90deg, rgba(255,223,113,1), rgba(255,180,23,1));
  color: ${(props) => props.theme.offblack};
  font-size: 18px;
  padding: 11px 28px;
  border: ${(props) => `2px solid ${props.theme.orange}`};
  font-weight: 500;
  border-radius: 27px;
  margin-right: 20px;
  text-decoration: none;
  line-height: 45px;

  :hover {
    cursor pointer;
    background: ${(props) => props.theme.orange};
  }
`;

export const ActionRow = styled.div`
  text-align: center;
  padding-top: ${(props) => (props.padding ? "20px" : "0")};
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Header = styled.h1`
  color: ${(props) => props.theme.offblack};
  font-size: 24px;
  margin: 0 0 30px 0;
  font-weight: 600;
`;

export const Paragraph = styled.p`
  color: ${(props) => props.theme.offblack};
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
`;

export const Next = styled.button`
  background: ${(props) => props.theme.blue};
  color: ${(props) => props.theme.white};
  font-size: 18;
  margin: 20px 10px 10px 10px;
  padding: 12px 36px;
  border-radius: 27px;
  font-weight: 500;
  border: none;
  cursor: pointer;

  &:hover {
    background: ${(props) => props.theme.hoverbutton};
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
`;

export const ButtonLink = styled(Link)`
  background: ${(props) => props.theme.blue};
  color: ${(props) => props.theme.white};
  font-size: 18;
  margin: 20px 10px 10px 10px;
  padding: 12px 36px;
  border-radius: 27px;
  font-weight: 500;
  border: none;
  cursor: pointer;

  &:hover {
    background: ${(props) => props.theme.hoverbutton};
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
`;

export const ErrorText = styled.p`
  color: ${(props) => props.theme.red};
  text-align: left;
  padding-left: 16px;
  margin: 10px 0 0 0;
  font-size: 14px;
`;

export const PageWrapper = styled.div`
  overflow: hidden;
  height: 100vh;
  margin: auto;
  display: flex;
  flex-direction: row;
  background: ${(props) => props.theme.background};

  ${mobile} {
    flex-direction: column;
    height: auto;
  }
`;

export const ContentWrapper = styled.div`
  margin: 10px 40px 60px 90px;

  ${mobile} {
    padding: 20px;
    margin: auto;
  }
`;

export const TooltipIcon = styled.span`
  color: ${(props) => props.theme.blue};
  position: relative;
  padding: 0 5px;
  border: 1px solid ${(props) => props.theme.blue};
  border-radius: 12px;
  font-size: 8px;
  margin-left: 4px;
  top: -1px;
  &:hover {
    cursor: pointer;
  }

  &:hover::before {
    --scale: 1;
  }

  &:before,
  &:after {
    box-sizing: border-box;
    position: absolute;
    line-height: normal;
    top: -0.25rem;
    left: 50%;
    transform: translateX(-4%) translateY(-103%) scale(var(--scale));
    transition: 50ms transform;
    z-index: 1;
  }

  &:before {
    --scale: 0;
    content: attr(data-text);
    color: black;
    background: ${(props) => props.theme.white};
    padding: 1rem;
    font-size: 12px;
    font-weight: 500;
    max-width: 250px;
    width: max-content;
    box-shadow: 0px 6px 16px -4px #cccccc;
    border-radius: 10px;
  }
`;
