import React from "react";
import styled from "styled-components";
import { Header, Paragraph, ActionRow, ButtonLink } from "../styles/global";

const ExternalSelection = () => {
  return (
    <>
      <Wrapper>
        <StyledHeader>You've selected your coverage!</StyledHeader>
        <Paragraph>What happens next?</Paragraph>
        <Paragraph>
          We will send an email with a secure link to complete your online
          application.
        </Paragraph>
      </Wrapper>
      <ActionRow>
        <ButtonLink to="/">Back</ButtonLink>
      </ActionRow>
    </>
  );
};

const Wrapper = styled.div`
  max-width: 875px;
  padding-bottom: 40px;
`;

const StyledHeader = styled(Header)`
  margin-bottom: 10px;
  color: ${(props) => props.theme.blue};
`;
export default ExternalSelection;
