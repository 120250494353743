/* eslint-disable */
import React from "react";
import styled from "styled-components";
import { ModalContentContainer } from "../../styles/global";
import { buildProsCons } from "../../utils/buildProsCons";
import { formatCurrency } from "../../utils/formatCurrency";
import quotesCardDetails from "../../data/quotesCardDetails";
import Modal from "../../components/Modal";
import { getLogo } from "../../utils/logos";

const QuoteDetails = ({ data, closeModal, modalIsOpen, appId }) => {
  const checkCarrier = (quote) => {
    return quote.carrier == data.carrier.displayName;
  };
  const quoteInfo = quotesCardDetails.find(checkCarrier);
  return (
    <Modal
      isOpen={modalIsOpen}
      closeModal={closeModal}
      label="quote-details"
      title="Quote Details"
    >
      <ModalContentContainer>
        <Row>
          {getLogo(data.carrier.name)}
          <DetailBlock>
            <Carrier>{data.carrier.displayName}</Carrier>
            <DetailRow>
              <DetailHeader>Annual Policy Cost</DetailHeader>
              <DetailInfo>{formatCurrency(data.totalAmount)}</DetailInfo>
            </DetailRow>
            <DetailRow>
              <DetailHeader>Carrier Product</DetailHeader>
              <DetailInfo>
                {data.admitted ? "Admitted" : "Non-Admitted"}
              </DetailInfo>
            </DetailRow>
            <DetailRow>
              <DetailHeader>Limit</DetailHeader>
              <DetailInfo>${data?.aggregateLimit?.toLocaleString()}</DetailInfo>
            </DetailRow>
            <DetailRow>
              <DetailHeader>Retention</DetailHeader>
              <DetailInfo>${data?.retention?.toLocaleString()}</DetailInfo>
            </DetailRow>
          </DetailBlock>
        </Row>
        <Row>
          <ProConLists>
            {buildProsCons(data.carrier.factList, true)}
          </ProConLists>
          <BodyContent>
            {quoteInfo?.description}
            Coverage Highlights Include:
            {quoteInfo?.coverageHighlights}
          </BodyContent>
        </Row>
      </ModalContentContainer>
    </Modal>
  );
};

const Carrier = styled.h2`
  margin-top: 10px;
`;

const DetailBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  margin-bottom: 20px;
  padding-left: 22px;
`;

const DetailHeader = styled.p`
  margin: 0;
  font-size: 14px;
  font-weight: 600;
`;

const DetailInfo = styled.p`
  margin: 0;
  font-size: 14px;
`;

const DetailRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
`;

const Logo = styled.img`
  width: 150px;
  margin-right: 133px;
`;

const BodyContent = styled.div`
  margin-left: 12px;
  width: 800px;
  font-size: 14px;
`;

const ProConLists = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
`;

export default QuoteDetails;
