import React, { useEffect, useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Header, Next, ActionRow, ErrorText } from "../../styles/global";
import Input from "../../components/Input";
import Dropdown from "../../components/Dropdown";
import { aggregateOptions, retentionOptions } from "../../data/selectOptions";
import { useStore } from "../../utils/store";

import { useUpdateApplication } from "../../hooks/useUpdateApplication";
import { omit } from "lodash";

const validationSchema = z.object({
  limitAggregate: z.string().nonempty({ message: "First name is required" }),
  preferredRetention: z.string().nonempty({ message: "Last name is required" }),
  targetEffectiveDate: z.preprocess((arg) => {
    if (typeof arg == "string" || arg instanceof Date) return new Date(arg);
  }, z.date()),
});

const Insurance = () => {
  const {
    control,
    register,
    watch,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      limitAggregate: "",
      preferredRetention: "",
      targetEffectiveDate: "",
    },
  });

  const form = useStore((state) => state.form);
  const updateForm = useStore((state) => state.updateForm);
  const updateStep = useStore((state) => state.updateStep);
  const updateFormErrors = useStore((state) => state.updateFormErrors);
  const step = useStore((state) => state.step);
  const stepArray = useStore((state) => state.stepArray);
  const updateStepArray = useStore((state) => state.updateStepArray);
  const mutation = useUpdateApplication(form.id);
  const [display, setDisplay] = useState(false);

  useEffect(() => {
    const subscription = watch((value) => updateForm(value));
    return () => subscription.unsubscribe();
  }, [watch]);

  const resetForm = useCallback(async () => {
    await reset(form);
    setDisplay(true);
  }, [reset]);

  useEffect(() => {
    resetForm();
  }, [resetForm]);

  const handleClick = () => {
    if (Object.keys(errors).length !== 0) return;
    reset((formValues) => ({
      ...formValues,
    }));
    updateFormErrors({ insuranceQuestions: errors });
    onSubmit();
  };

  const onSubmit = async () => {
    const newForm =
      form.domainPrim[0]?.domainPrim === "" ? omit(form, "domainPrim") : form;
    const payload = JSON.stringify(newForm);
    mutation.mutate(payload, {
      onSuccess: () => {
        navigateForward();
      },
      onError: (error) => {
        console.log(error);
      },
    });
  };

  const skip = () => {
    updateFormErrors({ insuranceQuestions: errors });
    onSubmit();
    navigateForward();
  };

  const navigateForward = () => {
    if (stepArray.indexOf(step) === -1) {
      updateStepArray(4);
    }
    updateStep(5);
  };

  return (
    <form>
      <Header>Insurance Questions</Header>
      {display && (
        <>
          <Dropdown
            name="limitAggregate"
            control={control}
            label="Aggregate Limit"
            placeholder="Select a limit"
            options={aggregateOptions}
            tooltip="SMBs typically purchase $1.0 million in limits around 75% of the time. Pre-launch startups or organizations that are larger, have higher or unique exposures, or specific contractual requirements may choose different limits."
          />
          <Input
            label="Target Effective Date"
            name="targetEffectiveDate"
            formatter="date"
            register={register}
            hasError={errors?.targetEffectiveDate?.message}
            control={control}
            tooltip="This should be the date you would like your coverage to begin, and it can be as soon as tomorrow."
          />
          <Dropdown
            name="preferredRetention"
            control={control}
            label="Preferred Retention"
            placeholder="Select a retention"
            options={retentionOptions}
            tooltip="Many carriers quote policies with retention amounts, similar to deductibles in health insurance.  Retention refers to the amount that you would pay out of pocket in the event of a claim before the insurer steps in.  Insurers like this as it aligns your risk with theirs, but what you may not know is that retentions benefit you as well by making your premium more affordable."
          />
          {errors?.targetEffectiveDate?.message && (
            <ErrorText>{errors.targetEffectiveDate.message}</ErrorText>
          )}
          <ActionRow>
            <Next onClick={() => updateStep(3)}>Back</Next>
            <Next onClick={handleSubmit(handleClick)}>Next</Next>
            {Object.keys(errors).length !== 0 && (
              <Next onClick={skip}>Skip</Next>
            )}
          </ActionRow>
        </>
      )}
    </form>
  );
};

export default Insurance;
