import React, { useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import Toggle from "../../components/Toggle";
import { Header, Next, ActionRow } from "../../styles/global";
import { useStore } from "../../utils/store";
import { useUpdateApplication } from "../../hooks/useUpdateApplication";
import { omit } from "lodash";

const validationSchema = z.object({
  opsUnique: z.object({
    opsRestrict: z.boolean({
      required_error: "This response is required",
      invalid_type_error: "This response is required",
    }),
    opsFranchise: z.boolean({
      required_error: "This response is required",
      invalid_type_error: "This response is required",
    }),
    cryptoCurrencyOrBlockchain: z.boolean({
      required_error: "This response is required",
      invalid_type_error: "This response is required",
    }),
    debtCollection: z.boolean({
      required_error: "This response is required",
      invalid_type_error: "This response is required",
    }),
    managedITProvider: z.boolean({
      required_error: "This response is required",
      invalid_type_error: "This response is required",
    }),
    paymentProcessing: z.boolean({
      required_error: "This response is required",
      invalid_type_error: "This response is required",
    }),
  }),
});

const TypeBusiness = () => {
  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    watch,
    reset,
  } = useForm({
    resolver: zodResolver(validationSchema),
  });

  const form = useStore((state) => state.form);
  const step = useStore((state) => state.step);
  const stepArray = useStore((state) => state.stepArray);
  const updateStepArray = useStore((state) => state.updateStepArray);
  const updateForm = useStore((state) => state.updateForm);
  const updateStep = useStore((state) => state.updateStep);
  const updateFormErrors = useStore((state) => state.updateFormErrors);
  const mutation = useUpdateApplication(form.id);

  useEffect(() => {
    const subscription = watch((value) => {
      return updateForm(value);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const resetAsyncForm = useCallback(async () => {
    await reset(form);
  }, [reset]);

  useEffect(() => {
    resetAsyncForm();
  }, [resetAsyncForm]);

  const handleClick = () => {
    if (Object.keys(errors).length !== 0) return;
    reset((formValues) => ({
      ...formValues,
    }));
    updateFormErrors({ typeBusiness: errors });
    onSubmit();
  };

  const onSubmit = async () => {
    const newForm =
      form.domainPrim[0]?.domainPrim === "" ? omit(form, "domainPrim") : form;
    const payload = JSON.stringify(newForm);
    mutation.mutate(payload, {
      onSuccess: () => {
        navigateForward();
      },
      onError: (error) => {
        console.log(error);
      },
    });
  };

  const skip = () => {
    updateFormErrors({ typeBusiness: errors });
    onSubmit();
    navigateForward();
  };

  const navigateForward = () => {
    if (stepArray.indexOf(step) === -1) {
      updateStepArray(2);
    }
    updateStep(3);
  };

  return (
    <form>
      <Header>Type of Business</Header>
      <Toggle
        label="Do your operations include cannabis, gambling, or adult content?"
        name="opsUnique.opsRestrict"
        register={register}
        control={control}
        hasError={errors?.opsUnique?.opsRestrict?.message}
        tooltip={`This is not a problem, and you're still in the right place!  However, these so-called "sin" businesses may require us to position your business in the best light for underwriters, so it is important for us to have this context.`}
      />
      <Toggle
        label="Is your organization a part of a franchise?"
        name="opsUnique.opsFranchise"
        register={register}
        control={control}
        hasError={errors?.opsUnique?.opsFranchise?.message}
        tooltip="Franchises can cause unique exposures which impacts underwriting appetite.  It's not a problem; it just affect the way we manage the application process on your behalf."
      />
      <Toggle
        label="Does your organization deal with cryptocurrency or blockchain?"
        name="opsUnique.cryptoCurrencyOrBlockchain"
        register={register}
        control={control}
        hasError={errors?.opsUnique?.cryptoCurrencyOrBlockchain?.message}
        tooltip="This includes accepting payments via cryptocurrency or relying on any blockchain technology.  We only ask so we can make sure to work with underwriters who have appetite for these types of risks, and not all do. "
      />
      <Toggle
        label="Does your organization engage in debt collection?"
        name="opsUnique.debtCollection"
        register={register}
        control={control}
        hasError={errors?.opsUnique?.debtCollection?.message}
        tooltip="We ask because this activity is highly regulated and can impact underwriting appetite.  It's not a problem; it just affects the way we manage the application process on your behalf. "
      />
      <Toggle
        label="Is your organization a managed IT provider?"
        name="opsUnique.managedITProvider"
        register={register}
        control={control}
        hasError={errors?.opsUnique?.managedITProvider?.message}
        tooltip="This includes monitoring, security, and configuration activities."
      />
      <Toggle
        label="Does your organization engage in payment processing?"
        name="opsUnique.paymentProcessing"
        register={register}
        control={control}
        hasError={errors?.opsUnique?.paymentProcessing?.message}
        tooltip="This includes anyone who manages the logistics of accepting credit card payments directly.  It's important because payment processors handle large quantities of sensitive information, which increases potential exposure.  We'll make sure this is taken into account when recommending the best policy."
      />
      <ActionRow>
        <Next onClick={() => updateStep(1)}>Back</Next>
        <Next onClick={handleSubmit(handleClick)}>Next</Next>
        {Object.keys(errors).length !== 0 && <Next onClick={skip}>Skip</Next>}
      </ActionRow>
    </form>
  );
};

export default TypeBusiness;
