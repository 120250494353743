export const lobLogic = (start) => {
  if (start.length === 1) {
    if (start.includes("CYBER")) {
      return "/application";
    } else {
      return "/coverage-selected";
    }
    //   if (start.lineOfBusiness.includes("Employment Practices Liability")) {
    //     window.location.href =
    //       "https://especialty.brokerbuddha.com/v3/clients/newapplication/commercial/employment_practices_liability_insurance";
    //   }
    //   if (start.lineOfBusiness.includes("Directors & Officers")) {
    //     window.location.href =
    //       "https://especialty.brokerbuddha.com/v3/clients/newapplication/commercial/directors_officers_insurance";
    //   }
    //   if (start.lineOfBusiness.includes("Reps & Warranty")) {
    //     window.location.href =
    //       "https://especialty.brokerbuddha.com/v3/clients/newapplication/commercial/reps_warranty_insurance";
    //   }
    //   if (start.lineOfBusiness.includes("Bonds")) {
    //     window.location.href =
    //       "https://especialty.propeller.insure/axelerator-public/";
    //   }
    //   if (start.lineOfBusiness.includes("Errors & Omissions")) {
    //     if (start.industry == "Consulting") {
    //       window.location.href =
    //         "https://especialty.brokerbuddha.com/v3/clients/newapplication/commercial/consulting_industry";
    //     }
    //     if (start.industry == "Design") {
    //       window.location.href = "https://especialty.draftrs.com/start";
    //     }
    //     if (start.industry == "Insurance") {
    //       window.location.href =
    //         "https://especialty.brokerbuddha.com/v3/clients/newapplication/commercial/insurance_industry";
    //     }
    //     if (start.industry == "Non-Profit") {
    //       window.location.href =
    //         "https://especialty.brokerbuddha.com/v3/clients/newapplication/commercial/non_profit_organizations";
    //     }
    //     if (start.industry == "Real Estate") {
    //       window.location.href =
    //         "https://especialty.brokerbuddha.com/v3/clients/newapplication/commercial/real_estate_industry";
    //     }
    //     if (start.industry == "Technology") {
    //       window.location.href =
    //         "https://especialty.prowritersins-app.com/CyberApplication/ContactInformation?Encrypted_MGA_Id=D5B3994D619897D9";
    //     }
    //     if (start.industry == "Trustee") {
    //       window.location.href =
    //         "https://especialty.brokerbuddha.com/v3/clients/newapplication/commercial/trustee_industry";
    //     }
    //     if (start.industry == "Other") {
    //       window.location.href = "https://especialty.com/";
    //     }
    //   }
    // } else {
    //   //multiple LOB here
    //   if (start.industry == "Consulting") {
    //     window.location.href =
    //       "https://especialty.brokerbuddha.com/v3/clients/newapplication/commercial/consulting_industry";
    //   }
    //   if (start.industry == "Design") {
    //     window.location.href = "https://especialty.draftrs.com/quote";
    //   }
    //   if (start.industry == "Insurance") {
    //     window.location.href =
    //       "https://especialty.brokerbuddha.com/v3/clients/newapplication/commercial/insurance_industry";
    //   }
    //   if (start.industry == "Non-Profit") {
    //     window.location.href =
    //       "https://especialty.brokerbuddha.com/v3/clients/newapplication/commercial/non_profit_organizations";
    //   }
    //   if (start.industry == "Real Estate") {
    //     window.location.href =
    //       "https://especialty.brokerbuddha.com/v3/clients/newapplication/commercial/real_estate_industry";
    //   }
    //   if (start.industry == "Technology") {
    //     window.location.href =
    //       "https://especialty.prowritersins-app.com/CyberApplication/ContactInformation?Encrypted_MGA_Id=D5B3994D619897D9";
    //   }
    //   if (start.industry == "Trustee") {
    //     window.location.href =
    //       "https://especialty.brokerbuddha.com/v3/clients/newapplication/commercial/trustee_industry";
    //   }
    //   if (start.industry == "Other") {
    //     window.location.href = "https://especialty.com/";
    //   }
  } else {
    return "/coverage-selected";
  }
};
