import { useQuery } from "@tanstack/react-query";
import { apiFactory } from "../utils/api";
import useAuth from "../auth/useAuth";

export const useGetUser = (email) => {
  const { getToken, getAdmin } = useAuth();
  const admin = getAdmin();

  const getUser = async () => {
    const token = await getToken();
    const api = apiFactory(token);
    return api.business.getUser(encodeURIComponent(email));
  };
  return useQuery(["get_user", email], getUser, {
    enabled: !admin,
    refetchInterval: false,
    refetchOnWindowFocus: false,
    retry: 1,
  });
};
