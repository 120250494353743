import React, { useState, useEffect } from "react";
import styled from "styled-components";
import QuoteDetails from "./QuoteDetails";
import SelectModal from "./SelectModal";
import { buildProsCons } from "../../utils/buildProsCons";
import { formatCurrency } from "../../utils/formatCurrency";
import { Next } from "../../styles/global";
import { getLogo } from "../../utils/logos";
import { isEmpty } from "lodash";
import { mobile } from "../../styles/responsive";
import { useStore } from "../../utils/store";

const QuoteCard = ({ data, recommended, appId }) => {
  const [displaySelect, setDisplaySelect] = useState(false);
  const [displayDetails, setDisplayDetails] = useState(false);
  const selected = useStore((state) => state.selected);
  const updateSelected = useStore((state) => state.updateSelected);

  const format = (amount) => {
    if (!amount) {
      return 0;
    } else {
      return `$${amount.toLocaleString()}`;
    }
  };

  const handleSelect = () => {
    setDisplaySelect(!displaySelect);
  };

  const handleDetails = () => {
    setDisplayDetails(!displayDetails);
  };

  useEffect(() => {
    if (
      !isEmpty(data.quoteAttributes.find((o) => o.attribute === "SELECTED"))
    ) {
      updateSelected(data);
      checkSelection();
    }
  }, [data, updateSelected]);

  const selectedCheck = data.id === selected.id;

  const checkSelection = () => {
    if (selectedCheck === false && !isEmpty(selected)) {
      return <Space></Space>;
    } else if (selectedCheck === true) {
      return <Selected>Selected</Selected>;
    } else {
      return (
        <>
          <SelectModal
            data={data}
            closeModal={handleSelect}
            modalIsOpen={displaySelect}
            appId={appId}
          />
          <SelectButton onClick={() => handleSelect()}>
            Select Quote
          </SelectButton>
        </>
      );
    }
  };

  return (
    <>
      {recommended ? (
        <Wrapper>
          <RecommendedText>RECOMMENDED FOR YOU</RecommendedText>
          <CardContainer recommended={recommended}>
            <TopWrapper>
              {getLogo(data.carrier.name)}
              <QuoteDetails
                data={data}
                closeModal={handleDetails}
                modalIsOpen={displayDetails}
                appId={appId}
              />
              <DetailsButton onClick={handleDetails}>
                More Details
              </DetailsButton>
              <InfoContainer>
                <Cost>
                  <span>Annual Cost</span>
                  <span>
                    {data.totalAmount > 0
                      ? formatCurrency(data.totalAmount)
                      : "Processing"}
                  </span>
                </Cost>
                <Info>
                  <span>Carrier Product</span>
                  <span>{data.admitted ? "Admitted" : "Non-Admitted"}</span>
                </Info>
                <Info>
                  <span>Limit</span>
                  <span>{format(data.aggregateLimit)}</span>
                </Info>
                <Info>
                  <span>Retention</span>
                  <span>{format(data.retention)}</span>
                </Info>
              </InfoContainer>
            </TopWrapper>

            {buildProsCons(data.carrier.factList)}
            {checkSelection()}
          </CardContainer>
        </Wrapper>
      ) : (
        <CardContainer>
          <TopWrapper>
            {getLogo(data.carrier.name)}
            <QuoteDetails
              data={data}
              closeModal={handleDetails}
              modalIsOpen={displayDetails}
              appId={appId}
            />
            <DetailsButton onClick={handleDetails}>More Details</DetailsButton>
            <InfoContainer>
              <Cost>
                <span>Annual Cost</span>
                <span>
                  {data.totalAmount > 0
                    ? formatCurrency(data.totalAmount)
                    : "Processing"}
                </span>
              </Cost>
              <Info>
                <span>Carrier Product</span>
                <span>{data.admitted ? "Admitted" : "Non-Admitted"}</span>
              </Info>
              <Info>
                <span>Limit</span>
                <span>{format(data.aggregateLimit)}</span>
              </Info>
              <Info>
                <span>Retention</span>
                <span>{format(data.retention)}</span>
              </Info>
            </InfoContainer>
          </TopWrapper>
          {buildProsCons(data.carrier.factList)}
          {checkSelection()}
        </CardContainer>
      )}
    </>
  );
};

const Space = styled.div`
  height: 75px;
`;

const Selected = styled.div`
  margin: 20px auto;
  background: ${(props) => props.theme.blue};
  color: ${(props) => props.theme.white};
  font-size: 18;
  margin: 20px 10px 10px 10px;
  margin: auto;
  padding: 12px 36px;
  border-radius: 27px;
  font-weight: 500;
  border: none;
  width: 80px;
  text-align: center;

  ${mobile} {
    margin-top: 20px;
    font-size: 16px;
    padding: 10px 24px;
  }
`;

const Cost = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 244px;
  margin: 5px 0 5px 30px;
  font-size: 18px;
  font-weight: 600;
  color: ${(props) => props.theme.darkblue};

  ${mobile} {
    font-size: 16px;
  }
`;

const TopWrapper = styled.div`
  text-align: center;
`;

const SelectButton = styled(Next)`
  margin: 20px auto;

  ${mobile} {
    margin-top: 20px;
    font-size: 16px;
    padding: 10px 24px;
  }
`;

const DetailsButton = styled(Next)`
  margin: 20px auto;
  background: ${(props) => props.theme.white};
  color: ${(props) => props.theme.offblack};
  border: 2px solid ${(props) => props.theme.offblack};
  cursor: pointer;
  padding: 8px 24px;
  font-size: 14px;

  ${mobile} {
    margin: 10px auto;
  }

  &:hover {
    border: 2px solid ${(props) => props.theme.blue};
    background: ${(props) => props.theme.blue};
    color: ${(props) => props.theme.white};
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};


`;

const InfoContainer = styled.div`
  margin-bottom: 20px;
`;

const RecommendedText = styled.div`
  background-color: ${(props) => props.theme.blue};
  color: ${(props) => props.theme.white};
  padding: 10px 16px;
  max-width: 205px;
  font-weight: 600;

  ${mobile} {
    font-size: 14px;
    max-width: 180px;
  }
`;

const Wrapper = styled.div`
  margin-top: -41px;
  padding: 10px 0;
  margin-right: -10px;
`;

const CardContainer = styled.div`
  height: ${(props) => (props.recommended ? "701px" : "auto")};
  min-width: 275px;
  max-width: 275px;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.white};
  box-shadow: 1px 3px 14px -6px rgba(0, 0, 0, 0.25);
  padding: 30px;
  margin-right: 20px;
  border-radius: 8px;
  border-top-left-radius: ${(props) => (props.recommended ? "0px" : "8px")};
  justify-content: space-between;
  border: ${(props) =>
    props.recommended ? `3px solid ${props.theme.blue}` : ""};

  ${mobile} {
    font-size: 13px;
    height: auto;
    margin-bottom: 16px;
    margin-right: 0;
    margin-left: ${(props) => (props.recommended ? "5px" : "0px")};
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 244px;
  margin: 5px 0 5px 30px;
`;

export default QuoteCard;
