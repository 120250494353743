import React, { useState } from "react";
import styled, { css } from "styled-components";
import { NumericFormat, PatternFormat } from "react-number-format";
import { useController } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { useStore } from "../utils/store";
import { Line, StyledTooltip, ErrorText } from "../styles/global";

const tomorrow = new Date();
tomorrow.setDate(tomorrow.getDate());
const current = tomorrow.toISOString().substr(0, 10);

export const input_styles = css`
  background-color: white;
  width: 100%;
  border-radius: 8px;
  padding: 12px 20px;
  box-shadow: 1px 3px 14px -6px rgba(0, 0, 0, 0.25);
  border: ${(props) =>
    props.haserror
      ? `1px solid ${props.theme.red}`
      : `1px solid ${props.theme.white}`};
  font-size: 14px;
  color: ${(props) => props.theme.offblack};
  width: 430px;
  &:focus {
    border: ${(props) =>
      props.haserror
        ? `1px solid ${props.theme.red}`
        : `1px solid ${props.theme.white}`};   
    outline: none;
  }

  ::placeholder {
      color: ${(props) => props.theme.placeholder};
`;

export const StyledInput = styled.input`
  ${input_styles}
`;

const TelephoneInput = styled(PatternFormat)`
  ${input_styles}
`;

const MoneyInput = styled(NumericFormat)`
  ${input_styles}
`;

export const NumberInput = styled(NumericFormat)`
  ${input_styles}
`;

const Input = ({
  label,
  register,
  name,
  placeholder,
  maxLength,
  pattern,
  hasError,
  control,
  formatter,
  disabled,
  tooltip,
  small,
}) => {
  const { field } = useController({
    name,
    control,
  });

  const [showTooltip, setShowTooltip] = useState(false);
  const location = useLocation();
  const updateAnnualRev = useStore((state) => state.updateAnnualRev);
  const annualRev = useStore((state) => state.annualRev);
  const form = useStore((state) => state.form);
  const start = useStore((state) => state.start);

  const path = location.pathname;
  const accountPage = path.includes("/account");

  const valueType = accountPage ? start[name] : form[name];

  const handleChange = (values) => {
    updateAnnualRev(values.formattedValue);
    if (field.name === "annualRevenue") {
      field.onChange(values !== "" ? values.floatValue.toString() : "");
    }
  };

  const handleFocus = () => {
    setShowTooltip(true);
  };

  const handleBlur = () => {
    setShowTooltip(false);
  };

  const getInput = (formatter) => {
    switch (formatter) {
      case "phone":
        return (
          <TelephoneInput
            placeholder={placeholder}
            format="(###) ###-####"
            mask="_"
            onValueChange={(values) => field.onChange(values.formattedValue)}
            name={name}
            haserror={hasError}
            onFocus={handleFocus}
            onBlur={handleBlur}
            value={form[name]}
            disabled={disabled}
          />
        );
      case "currency":
        return (
          <MoneyInput
            placeholder={placeholder}
            thousandSeparator=","
            thousandsGroupStyle="thousand"
            prefix="$"
            decimalScale={0}
            fixedDecimalScale
            onValueChange={(values) => handleChange(values)}
            name={name}
            haserror={hasError}
            onFocus={handleFocus}
            onBlur={handleBlur}
            value={annualRev}
          />
        );
      case "number":
        return (
          <NumberInput
            placeholder={placeholder}
            thousandSeparator=","
            thousandsGroupStyle="thousand"
            onValueChange={(values) => field.onChange(values.formattedValue)}
            name={name}
            haserror={hasError}
            onFocus={handleFocus}
            onBlur={handleBlur}
            value={form[name]}
            decimalScale={0}
          />
        );
      case "date":
        return (
          <StyledInput
            {...register(name)}
            placeholder={placeholder}
            haserror={hasError}
            type="date"
            min={current}
            max="2030-12-31"
            onFocus={handleFocus}
            onBlur={handleBlur}
            value={form[name]}
          />
        );
      case "array":
        return (
          <StyledInput
            name={name}
            placeholder={placeholder}
            haserror={hasError}
            onFocus={handleFocus}
            onBlur={handleBlur}
            disabled={disabled}
            value={
              field.value.domainPrim === ""
                ? field.value.domainPrim
                : field.value.replace(/^https?:\/\//, "")
            }
            onChange={(value) => field.onChange(value)}
          />
        );
      default:
        return (
          <StyledInput
            name={name}
            placeholder={placeholder}
            maxLength={maxLength}
            pattern={pattern}
            haserror={hasError}
            onFocus={handleFocus}
            onBlur={handleBlur}
            disabled={disabled}
            defaultValue={valueType}
            onChange={(value) => field.onChange(value)}
          />
        );
    }
  };

  return (
    <Container>
      <Wrapper haserror={hasError}>
        <Label htmlFor={label}>{label}</Label>
        {getInput(formatter)}
        {hasError && <ErrorText>{hasError}</ErrorText>}
      </Wrapper>
      {showTooltip && tooltip && (
        <>
          <Line haserror={hasError} />
          <StyledTooltip small={small} haserror={hasError}>
            {tooltip}
          </StyledTooltip>
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

const Wrapper = styled.div`
  margin-bottom: ${(props) => (props.haserror ? "10px" : "30px")};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  padding: 0 16px 8px 16px;
  color: ${(props) => props.theme.offblack};
  font-size: 16px;
  weight: 500;
  overflow-wrap: break-word;
  max-width: 350px;
  text-align: left;
`;

export default Input;
