import { useMutation } from "@tanstack/react-query";
import { apiFactory } from "../utils/api";
import useAuth from "../auth/useAuth";

export const useCreateCyberQuote = () => {
  const { getToken } = useAuth();

  const createCyberQuote = async (payload) => {
    const token = await getToken();
    const api = apiFactory(token);

    return api.business.createCyberQuote(payload);
  };
  return useMutation(createCyberQuote);
};
