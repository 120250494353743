export const formatCurrency = (number) => {
  const formatter = new Intl.NumberFormat("en-us", {
    currency: "USD",
    style: "currency",
  });

  return formatter.format(number);
};

export const formatMoney = (number) => {
  const formatter = new Intl.NumberFormat("en-us", {
    currency: "USD",
    style: "currency",
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });

  return formatter.format(number);
};
