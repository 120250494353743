import React, { useEffect } from "react";
import styled from "styled-components";
import { formatMoney } from "../../utils/formatCurrency";
import { useStore } from "../../utils/store";
import { mobile } from "../../styles/responsive";

const ApplicantDrawer = ({ application, limitsQuoted }) => {
  const displayLimit = useStore((state) => state.displayLimit);
  const updateDisplayLimit = useStore((state) => state.updateDisplayLimit);

  useEffect(() => {
    updateDisplayLimit(limitsQuoted[0]);
  }, []);

  return (
    <ApplicantInfo>
      <Header>Policy Limit</Header>
      <LimitGrid>
        {limitsQuoted.map((quoteLimit) => {
          return (
            <LimitButton
              onClick={() => updateDisplayLimit(quoteLimit)}
              selected={displayLimit === quoteLimit}
              key={quoteLimit}
            >
              {formatMoney(quoteLimit.toString())}
            </LimitButton>
          );
        })}
      </LimitGrid>
      <AppHeader>Application Info</AppHeader>
      <AppDetail>{application.organizationName}</AppDetail>
      <Row>
        <AppDetail>Annual Sales:</AppDetail>
        <AppDetail>{formatMoney(application.annualRevenue)}</AppDetail>
      </Row>
      <AppDetail>Industry:</AppDetail>
      <AppDetail>{application.primaryOperations}</AppDetail>
    </ApplicantInfo>
  );
};

const AppDetail = styled.p`
  margin: 5px 10px 0 0;

  ${mobile} {
    font-size: 13px;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const AppHeader = styled.h3`
  margin: 60px 0 10px 0;
  color: ${(props) => props.theme.white};

  ${mobile} {
    margin: 20px 0 10px 0;
    font-size: 15px;
  }
`;

const Header = styled.h3`
  color: ${(props) => props.theme.white};
  margin-top: 60px;

  ${mobile} {
    margin-top: 10px;
    font-size: 15px;
  }
`;

const ApplicantInfo = styled.div`
  color: ${(props) => props.theme.white};
  margin: 30px;
`;

const LimitButton = styled.button`
  display: block;
  font-size: 10px;
  padding: 1em 2em;
  text-align: center;
  user-select: none;
  cursor: pointer;
  background-color: ${(props) =>
    props.selected ? props.theme.blue : props.theme.white};
  color: ${(props) =>
    props.selected ? props.theme.white : props.theme.offblack};
  border: ${(props) =>
    props.selected
      ? `1px solid ${props.theme.blue}`
      : `1px solid ${props.theme.offblack}`};
  text-transform: uppercase;
  border-radius: 2em;
  text-decoration: none;
  box-sizing: border-box;
  margin-right: 10px;
  font-size: 12px;
  font-family: "Nunito Sans", sans-serif;
`;

const LimitGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 5px;
`;

export default ApplicantDrawer;
