import React from "react";
import Modal from "./Modal";
import styled from "styled-components";
import {
  Header,
  Paragraph,
  Column,
  ModalContentContainer,
} from "../styles/global";
import Chat from "../images/chat.svg";
import Email from "../images/email.svg";
import Phone from "../images/phone.svg";

const GetHelpModal = ({ closeModal, modalIsOpen }) => {
  return (
    <Modal
      isOpen={modalIsOpen}
      closeModal={closeModal}
      label="get-help"
      title="Need help? Ask an expert"
      onSubmit={closeModal}
      buttonText={"Back"}
    >
      <ModalContentContainer>
        <Title>There are lots of ways to get in touch.</Title>
        <Row>
          <Icon src={Chat} alt="chat-bubble" />
          <Column>
            <StyledHeader>Live Chat</StyledHeader>
            <StyledParagraph>
              Use the icon in the lower right of your screen to chat with an
              insurance specialist
            </StyledParagraph>
          </Column>
        </Row>
        <Row>
          <Icon src={Email} alt="chat-bubble" />
          <Column>
            <StyledHeader>Email</StyledHeader>
            <EmailRow>
              <StyledParagraph>Send us an email at</StyledParagraph>
              <EmailLink href="mailto:solutions@especialty.com">
                solutions@especialty.com
              </EmailLink>
            </EmailRow>
          </Column>
        </Row>
        <Row>
          <Icon src={Phone} alt="chat-bubble" />
          <Column>
            <StyledHeader>Call</StyledHeader>
            <EmailRow>
              <StyledParagraph>Schedule a call with us at</StyledParagraph>
              <EmailLink href="https://calendly.com/especialtysolutions/30min">
                calendly.com/especialtysolutions
              </EmailLink>
            </EmailRow>
          </Column>
        </Row>
      </ModalContentContainer>
    </Modal>
  );
};

const Icon = styled.img`
  margin-top: 20px;
  padding-left: 16px;
`;

const Title = styled(Header)`
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 20px;
`;

const StyledHeader = styled(Header)`
  margin-bottom: 8px;
  margin-top: 32px;
  font-size: 20px;
`;

const StyledParagraph = styled(Paragraph)`
  margin: 0;
  font-size: 16px;
`;

const EmailLink = styled.a`
  margin: 0;
  font-size: 16px;
  line-height: 28px;
  padding-left: 6px;
  color: ${(props) => props.theme.darkblue};

  :hover {
    color: ${(props) => props.theme.blue};
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 16px;
`;

const EmailRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export default GetHelpModal;
