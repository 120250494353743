import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { isEmpty, omit } from "lodash";
import { useNavigate } from "react-router-dom";
import { useSubmitApplication } from "../../hooks/useSubmitApplication";
import { useResubmitApplication } from "../../hooks/useResubmitApplication";
import { useStore, current } from "../../utils/store";
import { Header, Next, ActionRow, ErrorText } from "../../styles/global";
import Edit from "../../images/whiteedit.svg";
import ReviewItem from "../../components/ReviewItem";

const Review = () => {
  const [requote, setRequote] = useState(false);
  const form = useStore((state) => state.form);
  const updateForm = useStore((state) => state.updateForm);
  const updateStep = useStore((state) => state.updateStep);
  const formErrors = useStore((state) => state.formErrors);
  const quoted = useStore((state) => state.quoted);
  const mutationSubmit = useSubmitApplication(form.id);
  const mutationResubmit = useResubmitApplication(form.id);
  const navigate = useNavigate();

  useEffect(() => {
    if (form.id === quoted.id) {
      setRequote(true);
      updateForm({ ...form, status: "READY_TO_SUBMIT" });
    }
  }, []);

  const clearForm = () => {
    updateForm({
      id: 0,
      status: "SAVED",
      organizationName: "",
      domainPrim: [{ domainPrim: "" }],
      contactFirstName: "",
      contactLastName: "",
      contactEmail: "",
      businessPhone: "",
      primaryOperations: "",
      claimsIncident: "",
      targetEffectiveDate: current,
      organizationAddress: {
        unit: "",
        city: "",
        state: "",
        zipCode: "",
        street: "",
      },
    });
  };

  const onSubmit = async () => {
    const newForm =
      form.domainPrim[0]?.domainPrim === "" ? omit(form, "domainPrim") : form;
    const payload = JSON.stringify(newForm);
    if (requote) {
      await mutationResubmit.mutate(payload, {
        onSuccess: () => {
          navigate("/success");
          clearForm();
        },
        onError: (error) => {
          console.log(error);
        },
      });
    } else {
      await mutationSubmit.mutate(payload, {
        onSuccess: () => {
          navigate("/success");
          clearForm();
        },
        onError: (error) => {
          console.log(error);
        },
      });
    }
  };

  const disableSubmit =
    !isEmpty(formErrors.businessInfo) ||
    !isEmpty(formErrors.typeBusiness) ||
    !isEmpty(formErrors.policyQuestions) ||
    !isEmpty(formErrors.insuranceQuestions);

  const displayAddress = () => {
    if (
      form.organizationAddress.street !== "" &&
      form.organizationAddress.street !== null &&
      form.organizationAddress.street !== undefined
    ) {
      return `${form.organizationAddress.street} ${form.organizationAddress.city}, \n${form.organizationAddress.state} ${form.organizationAddress.zipCode}`;
    } else {
      return "";
    }
  };

  return (
    <>
      <Header>Application Review</Header>
      <Wrapper>
        <Left>
          <CardContainer>
            <Tab>
              <TabTitle>Business Info</TabTitle>
              <Icon src={Edit} alt="edit icon" onClick={() => updateStep(1)} />
            </Tab>
            <Card>
              <ReviewItem
                name="Organization Name"
                value={form.organizationName}
              />
              {form.domainPrim[0]?.domainPrim !== "" && (
                <ReviewItem name="Website" value={form.domainPrim[0]} />
              )}
              <ReviewItem name="Phone Number" value={form.businessPhone} />
              <ReviewItem name="Email" value={form.contactEmail} />
              <ReviewItem name="Business Address" value={displayAddress()} />
              <ReviewItem name="Employee Count" value={form.employeeCount} />
              <ReviewItem
                name="Year Established"
                value={form.yearEstablished}
              />
              <ReviewItem name="Annual Sales" value={form.annualRevenue} />
              <ReviewItem
                name="Organization Type"
                value={form.organizationType}
              />
              <ReviewItem
                name="Primary Operations"
                value={form.primaryOperations}
              />
            </Card>
          </CardContainer>
          <CardContainer>
            <Tab>
              <TabTitle>Policy Questions</TabTitle>
              <Icon src={Edit} alt="edit icon" onClick={() => updateStep(3)} />
            </Tab>
            <CardYesNo>
              <ReviewItem
                name="Multi-Factor Authentication?"
                value={form.uwMfa}
                yesNo={true}
              />
              <ReviewItem
                name="MFA enabled for remote access?"
                value={form.uwMfaNetwork}
                yesNo={true}
              />
              <ReviewItem
                name="MFA enabled for email access?"
                value={form.uwMfaEmail}
                yesNo={true}
              />
              <ReviewItem
                name="MFA required for external access?"
                value={form.uwMfaRequired}
                yesNo={true}
              />
              <ReviewItem
                name="Potential rise to cyber claim?"
                value={form.lossWarranty}
                yesNo={true}
              />
              <ReviewItem
                name="Current cybersecurity coverage?"
                value={form.currentCoverage}
                yesNo={true}
              />
              {form.expirationDate && (
                <ReviewItem
                  name="Exp date of current policy"
                  value={form.expirationDate}
                  row={true}
                />
              )}
            </CardYesNo>
          </CardContainer>
        </Left>
        <Right>
          <CardContainer>
            <Tab>
              <TabTitle>Business Type</TabTitle>
              <Icon src={Edit} alt="edit icon" onClick={() => updateStep(2)} />
            </Tab>
            <CardYesNo>
              <ReviewItem
                name="Cannabis, gambling, or adult content?"
                value={form.opsUnique?.opsRestrict}
                yesNo={true}
              />
              <ReviewItem
                name="Part of a franchise?"
                value={form.opsUnique?.opsFranchise}
                yesNo={true}
              />
              <ReviewItem
                name="Cyptocurrency or blockchain?"
                value={form.opsUnique?.cryptoCurrencyOrBlockchain}
                yesNo={true}
              />
              <ReviewItem
                name="Debt collection?"
                value={form.opsUnique?.debtCollection}
                yesNo={true}
              />
              <ReviewItem
                name="Managed IT provider?"
                value={form.opsUnique?.managedITProvider}
                yesNo={true}
              />
              <ReviewItem
                name="Payment processing?"
                value={form.opsUnique?.paymentProcessing}
                yesNo={true}
              />
            </CardYesNo>
          </CardContainer>
          <CardContainer>
            <Tab>
              <TabTitle>Insurance Questions</TabTitle>
              <Icon src={Edit} alt="edit icon" onClick={() => updateStep(4)} />
            </Tab>
            <Card>
              <ReviewItem name="Aggregate Limit" value={form.limitAggregate} />
              <ReviewItem
                name="Target Effective Date"
                value={form.targetEffectiveDate}
              />
              <ReviewItem
                name="Preferred Retention"
                value={form.preferredRetention}
              />
            </Card>
          </CardContainer>
        </Right>
      </Wrapper>
      {disableSubmit && (
        <ErrorText>
          In order to submit, please go back and fix errors.
        </ErrorText>
      )}
      <ActionRow>
        <Next onClick={() => updateStep(4)}>Back</Next>
        <Next onClick={() => onSubmit()} disabled={disableSubmit}>
          Submit
        </Next>
      </ActionRow>
    </>
  );
};

const Icon = styled.img`
  &:hover {
    cursor: pointer;
  }
`;

const TabTitle = styled.p`
  margin: 0;
  padding-right: 10px;
`;

const Tab = styled.div`
  background-color: ${(props) => props.theme.darkblue};
  color: ${(props) => props.theme.white};
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 8px 12px;
  border-radius: 8px 8px 0 0;
  max-width: 180px;
`;

const Card = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;
  box-shadow: 1px 3px 14px -6px rgba(0, 0, 0, 0.25);
  background-color: ${(props) => props.theme.white};
  border-radius: 8px;
  width: 400;
  padding: 20px;
  margin-bottom: 45px;
`;

const CardYesNo = styled.div`
  box-shadow: 1px 3px 14px -6px rgba(0, 0, 0, 0.25);
  background-color: ${(props) => props.theme.white};
  border-radius: 8px;
  max-width: 400px;
  padding: 10px;
  margin-bottom: 45px;
`;

const CardContainer = styled.div``;

const Wrapper = styled.div`
  display: flex;
`;

const Left = styled.div`
  margin-right: 45px;
`;

const Right = styled.div``;

export default Review;
