import { create } from "zustand";

const tomorrow = new Date();
tomorrow.setDate(tomorrow.getDate());
export const current = tomorrow.toISOString().substr(0, 10);

export const useStore = create((set) => ({
  step: 1,
  updateStep: (step) => set({ step }),
  stepArray: [],
  updateStepArray: (payload) => {
    set((state) => (state.stepArray = [...state.stepArray, payload]));
  },
  quoted: {},
  updateQuoted: (quoted) => set({ quoted }),
  ready: false,
  updateReady: (ready) => set({ ready }),
  searchEmail: "",
  updateSearchEmail: (searchEmail) => set({ searchEmail }),
  chatQuery: "",
  updateChatQuery: (chatQuery) => set({ chatQuery }),
  admin: false,
  updateAdmin: (admin) => set({ admin }),
  selected: {},
  updateSelected: (selected) => set({ selected }),
  displayLimit: 0,
  updateDisplayLimit: (displayLimit) => set({ displayLimit }),
  updateStart: (payload) => {
    set((state) => (state.start = { ...state.start, ...payload }));
  },
  start: {
    firstName: "",
    lastName: "",
    email: "",
    industry: "",
    lineOfBusiness: [],
  },
  updateLob: (payload, checked) => {
    set((state) => {
      if (checked) {
        return (state.start.lineOfBusiness = [
          ...state.start.lineOfBusiness,
          payload,
        ]);
      } else {
        return (state.start.lineOfBusiness =
          state.start.lineOfBusiness.filter((item) => item !== payload) || []);
      }
    });
  },
  fullAddress: "",
  updateFullAddress: (fullAddress) => set({ fullAddress }),
  annualRev: "",
  updateAnnualRev: (annualRev) => set({ annualRev }),
  updateForm: (payload) => {
    set((state) => (state.form = { ...state.form, ...payload }));
  },
  formErrors: {
    basicInfo: {},
    coverageSelection: {},
    businessInfo: {},
    typeBusiness: {},
    policyQuestions: {},
    insuranceQuestions: {},
  },
  updateFormErrors: (payload) => {
    set((state) => (state.formErrors = { ...state.formErrors, ...payload }));
  },
  form: {
    id: 0,
    status: "SAVED",
    organizationName: "",
    domainPrim: [{ domainPrim: "" }],
    contactFirstName: "",
    contactLastName: "",
    contactEmail: "",
    businessPhone: "",
    primaryOperations: "",
    claimsIncident: "",
    targetEffectiveDate: current,
    organizationAddress: {
      unit: "",
      city: "",
      state: "",
      zipCode: "",
      street: "",
    },
  },
}));
