import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import styled from "styled-components";
import QuoteCard from "./QuoteCard";
import { useStore } from "../../utils/store";
import { useGetQuotes } from "../../hooks/useGetQuotes";
import { useGetAdminQuotes } from "../../hooks/useGetAdminQuotes";
import { useGetMLQuotes } from "../../hooks/useGetMLQuotes";
import { useGetSearchQuotes } from "../../hooks/useGetSearchQuotes";
import LoadingGif from "../../components/LoadingGif";
import useAuth from "../../auth/useAuth";
import { isEmpty } from "lodash";
import ArrowLeft from "../../images/arrowleft.svg";
import ArrowRight from "../../images/arrowright.svg";
import { mobile } from "../../styles/responsive";

const QuoteGrid = () => {
  const [scrollX, setScrollX] = useState(0);
  const [scrollEnd, setScrollEnd] = useState(false);
  const [filtered, setFiltered] = useState([]);
  const [appId, setAppId] = useState(0);
  const [rec, setRec] = useState({});
  const [showRightArrow, setShowRightArrow] = useState(false);
  const { email, getAdmin } = useAuth();
  const admin = getAdmin();
  const searchEmail = useStore((state) => state.searchEmail);
  const updateSelected = useStore((state) => state.updateSelected);
  const updateDisplayLimit = useStore((state) => state.updateDisplayLimit);
  const displayLimit = useStore((state) => state.displayLimit);
  const QUOTE_STATUS = {
    ACTIVE: "ACTIVE",
    DECLINATION: "DECLINATION",
  };
  Object.freeze(QUOTE_STATUS);
  const scrl = useRef(0);

  const searchQuotesDisplay = searchEmail !== "" && admin;
  const adminQuoteDisplay = searchEmail === "" && admin;

  const { data: quotesData, isInitialLoading: quotesLoading } = useGetQuotes(
    email,
    !admin
  );
  const { data: searchQuotesData, isInitialLoading: quotesLoadingSearch } =
    useGetSearchQuotes(searchEmail, searchQuotesDisplay);
  const { data: adminQuotesData, isInitialLoading: adminQuotesLoading } =
    useGetAdminQuotes(admin && adminQuoteDisplay);

  const { data: mlQuotesData, isInitialLoading: mlQuotesLoading } =
    useGetMLQuotes();

  const filterQuotes = (quoteTypes) => {
    if (quoteTypes?.application) {
      setAppId(quoteTypes.application.id);
    }
    if (quoteTypes?.quotes) {
      const firstFilter = quoteTypes.quotes.filter(
        (d) => d.quoteStatus.toLowerCase() === ("quoted" || "active")
      );
      const secondFilter = firstFilter.filter((quote) => {
        return quote.aggregateLimit === displayLimit;
      });
      setFiltered(secondFilter);

      secondFilter.map((q, i) => {
        if (!isEmpty(rec) && rec.aggregateLimit !== displayLimit) {
          setRec({});
        }
        if (q.quoteAttributes.length > 0) {
          if (q.quoteAttributes.find((o) => o.attribute === "RECOMMENDED")) {
            secondFilter.splice(i, 1);
            if (q.aggregateLimit === displayLimit) {
              setRec(q);
            } else {
              setRec({});
            }
            setFiltered(secondFilter);
          }
          if (q.quoteAttributes.find((o) => o.attribute === "SELECTED")) {
            updateSelected(q);
          } else {
            updateSelected({});
          }
        }
      });
    } else {
      setFiltered([]);
    }
  };

  useEffect(() => {
    if (mlQuotesData != null) {
      filterQuotes(mlQuotesData);
    }
    if (quotesData != null) {
      filterQuotes(quotesData);
    }
    if (adminQuotesData != null) {
      filterQuotes(adminQuotesData);
    }
    if (searchQuotesData !== null && searchQuotesData !== undefined) {
      filterQuotes(searchQuotesData);
    }
  }, [
    displayLimit,
    mlQuotesData,
    searchQuotesData,
    adminQuotesData,
    quotesData,
  ]);

  useEffect(() => {
    if (mlQuotesData !== null && mlQuotesData !== undefined) {
      updateDisplayLimit(mlQuotesData.limitsQuoted[0]);
      filterQuotes(mlQuotesData);
    }
    if (quotesData !== null && quotesData !== undefined) {
      updateDisplayLimit(quotesData.limitsQuoted[0]);
      filterQuotes(quotesData);
    }
    if (adminQuotesData !== null && adminQuotesData !== undefined) {
      updateDisplayLimit(adminQuotesData.limitsQuoted[0]);
      filterQuotes(adminQuotesData);
    }
    if (searchQuotesData !== null && searchQuotesData !== undefined) {
      updateDisplayLimit(searchQuotesData.limitsQuoted[0]);
      filterQuotes(searchQuotesData);
    }
  }, [mlQuotesData, adminQuotesData, quotesData, searchQuotesData]);

  const slide = (shift) => {
    scrl.current.scrollLeft += shift;
  };

  const length = isEmpty(rec) ? filtered.length : filtered.length + 1;

  useLayoutEffect(() => {
    if (scrl.current.clientWidth >= scrl.current.scrollWidth && length > 3) {
      setShowRightArrow(true);
    } else {
      setShowRightArrow(false);
    }
  }, [scrl, displayLimit]);

  const scrollCheck = () => {
    setScrollX(scrl.current.scrollLeft);
    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setScrollEnd(true);
    } else {
      setScrollEnd(false);
    }
  };

  const loadQuotes = () => {
    if (
      mlQuotesData?.length === 0 ||
      quotesData?.length === 0 ||
      adminQuotesData?.length === 0 ||
      searchQuotesData?.length === 0
    ) {
      return (
        <h2 className="center">
          You don't have any quotes yet. Check back soon!
        </h2>
      );
    } else if (
      adminQuotesLoading ||
      quotesLoading ||
      mlQuotesLoading ||
      quotesLoadingSearch
    ) {
      return <LoadingGif />;
    } else if (
      mlQuotesData !== null ||
      quotesData !== null ||
      adminQuotesData !== null ||
      searchQuotesData !== null
    ) {
      return (
        <>
          <QuotesContainer>
            {!isEmpty(rec) && (
              <QuoteCard data={rec} recommended={true} appId={appId} />
            )}
            {scrollX !== 0 && length > 3 && (
              <ScrollButton onClick={() => slide(-350)}>
                <img src={ArrowLeft} alt="left arrow" />
              </ScrollButton>
            )}
            <GridContainer ref={scrl} onScroll={scrollCheck}>
              {filtered.map((q, i) => {
                return (
                  <QuoteCard data={q} key={Math.random(i)} appId={appId} />
                );
              })}
            </GridContainer>
            {!scrollEnd && showRightArrow && (
              <ScrollButton onClick={() => slide(+350)}>
                <img src={ArrowRight} alt="right arrow" />
              </ScrollButton>
            )}
          </QuotesContainer>
          {(filtered.length > 0 || !isEmpty(rec)) && (
            <Total>Total quotes returned: {length}</Total>
          )}
          {filtered.length < 0 && isEmpty(rec) && (
            <h2 className="center">Something went wrong, please try again.</h2>
          )}
        </>
      );
    } else {
      return (
        <h2 className="center">
          You don't have any quotes yet. Check back soon!
        </h2>
      );
    }
  };

  return <>{loadQuotes()}</>;
};

const Total = styled.p`
  padding-left: 20px;

  ${mobile} {
    font-size: 13px;
    margin-left: 16px;
  }
`;

const ScrollButton = styled.button`
  height: 50px;
  width: 30px;
  margin: auto;
  background: ${(props) => props.theme.blue};
  border: none;
  border-radius: 20px;

  ${mobile} {
    display: none;
  }
`;

const GridContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  padding: 10px;
  margin-left: 10px;
  margin-right: 10px;
  width: 100%;

  ${mobile} {
    flex-direction: column;
    overflow: auto;
    margin-left: 0px;
    margin-right: 0px;
  }
`;

const QuotesContainer = styled.div`
  display: flex;
  flex-direction: row;

  ${mobile} {
    flex-direction: column;
  }
`;

export default QuoteGrid;
