import { useQuery } from "@tanstack/react-query";
import { apiFactory } from "../utils/api";
import { useSearchParams } from "react-router-dom";

export const useGetMLQuotes = () => {
 
  const [searchParams] = useSearchParams();
  const qid = searchParams.get("qid");
  const getMLQuotes = async () => {
    const api = apiFactory();
    if (qid) {
    return api.commercial.getMLQuotes(qid);
  } else {
    return null;
  }
};

  return useQuery(["ml_quotes", qid], getMLQuotes, {
    enabled: true,
    refetchInterval: false,
    refetchOnWindowFocus: false,
    retry: 1,
  });
};