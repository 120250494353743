import React from "react";
import styled from "styled-components";
import { useController } from "react-hook-form";
import { ErrorText } from "../styles/global";
import { useStore } from "../utils/store";

const Toggle = ({
  label,
  register,
  control,
  name,
  tooltip,
  hasError,
  start,
}) => {
  const { field } = useController({
    name,
    control,
  });

  const updateStart = useStore((state) => state.updateStart);

  const handleChange = (value) => {
    if (start) {
      updateStart({ [name]: value });
    }
    field.onChange(value);
  };

  return (
    <Container>
      <TooltipCard>
        <Wrapper>
          <Column>
            <Label htmlFor={name}>{label}</Label>
            {hasError && <StyledErrorText>{hasError}</StyledErrorText>}
          </Column>
          <ToggleContainer>
            <Option
              htmlFor={`option-yes-${name}`}
              selected={field.value === true}
              position="left"
            >
              <StyledToggle
                {...register(name)}
                onChange={() => handleChange(true)}
                id={`option-yes-${name}`}
                type="radio"
                value={true}
              />
              Yes
            </Option>
            <Option
              htmlFor={`option-no-${name}`}
              selected={field.value === false}
              position="right"
            >
              <StyledToggle
                {...register(name)}
                onChange={() => handleChange(false)}
                id={`option-no-${name}`}
                type="radio"
                value={false}
              />
              No
            </Option>
          </ToggleContainer>
        </Wrapper>
        {tooltip && (
          <TooltipBox haserror={hasError}>
            <Line />
            <TooltipText>{tooltip}</TooltipText>
          </TooltipBox>
        )}
      </TooltipCard>
    </Container>
  );
};

const StyledErrorText = styled(ErrorText)`
  padding: 0;
  margin: 10px 0 -26px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Line = styled.div`
  width: 30px;
  border-bottom: ${(props) => `3px dotted ${props.theme.toolTipBackground}`};
  margin: ${(props) => (props.haserror ? "45px auto auto auto" : "auto")};
`;

const Wrapper = styled.div`
  margin-bottom: ${(props) => (props.haserror ? "10px" : "0")};
  width: 650px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const TooltipBox = styled.div`
  display: flex;
  visibility: hidden;
`;

const TooltipCard = styled.div`
  display: flex;
  & ${Wrapper}:hover + ${TooltipBox} {
    visibility: visible;
  }
`;

const TooltipText = styled.div`
  margin: auto;
  color: #4e5357;
  font-size: 14px;
  border-radius: 8px;
  background-color: #c4dff1;
  width: 350px;
  padding: 12px;
`;

const handleBlockPosition = (position) => {
  switch (position) {
    case "left":
      return "30px 0 0 30px";
    case "right":
      return "0 30px 30px 0";
    default:
      return "0";
  }
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

const ToggleContainer = styled.div``;

const Option = styled.label`
  box-shadow: 1px 3px 14px -6px rgba(0, 0, 0, 0.25);
  padding: 10px 20px;
  border-radius: ${(props) => handleBlockPosition(props.position)};
  margin: 3px;
  display: inline-block;
  color: ${(props) =>
    props.selected ? props.theme.white : props.theme.offblack};
  background-color: ${(props) =>
    props.selected ? props.theme.blue : props.theme.white};

  &:hover {
    cursor: pointer;
  }
`;

const StyledToggle = styled.input`
  display: none;
`;

const Label = styled.label`
  color: ${(props) => props.theme.offblack};
  font-size: 16px;
  weight: 500;
  overflow-wrap: break-word;
  text-align: left;
  max-width: 479px;
`;

export default Toggle;
