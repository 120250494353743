import React from "react";
import styled from "styled-components";
import { Header, Paragraph, CtaButton, ActionRow } from "../styles/global";

const Success = () => {
  return (
    <>
      <Wrapper>
        <StyledHeader>We've received your application!</StyledHeader>
        <Paragraph>What happens next?</Paragraph>
        <Paragraph>
          Now, our experts are working on finding you the most comprehensive
          coverage for the best price. We will review multiple quotes from top
          insurance carriers to provide you with a policy recommendation.
        </Paragraph>
        <Paragraph>
          We will send an email notification when a quote proposal is available
          in your account.
        </Paragraph>
      </Wrapper>
      <ActionRow>
        <CtaButton to="/">Back to Home Page</CtaButton>
      </ActionRow>
    </>
  );
};

const Wrapper = styled.div`
  max-width: 875px;
  padding-bottom: 20px;
`;

const StyledHeader = styled(Header)`
  margin-bottom: 10px;
  color: ${(props) => props.theme.blue};
`;
export default Success;
