import AtBayLogo from "../images/logos/ReactAtBayLogo";
import BeazleyLogo from "../images/logos/ReactBeazleyLogo";
import CfcLogo from "../images/logos/ReactCfcLogo";
import CoalitionLogo from "../images/logos/ReactCoalitionLogo";
import CorvusLogo from "../images/logos/ReactCorvusLogo";
import CowbellLogo from "../images/logos/ReactCowbellLogo";
import ElphaSecureLogo from "../images/logos/ReactElphaSecureLogo";
import AxisCapitalLogo from "../images/logos/ReactAxisCapitalLogo";
import ERiskLogo from "../images/logos/ReactERiskLogo"
import MeasuredLogo from "../images/logos/ReactMeasuredLogo";
import NodeLogo from "../images/logos/ReactNodoLogo";
import TokioMarineLogo from "../images/logos/ReactTokioMarineLogo";
import CyberLogo from "../images/logos/ReactCyberLogo";

export const getLogo = (carrier) => {
  if (carrier.includes("At Bay")) {
    return <AtBayLogo />;
  } else if (carrier.includes("Beazley")) {
    return <BeazleyLogo />;
  } else if (carrier.includes("Cfc")) {
    return <CfcLogo />;
  } else if (carrier.includes("Coalition")) {
    return <CoalitionLogo />;
  } else if (carrier.includes("Corvus")) {
    return <CorvusLogo />;
  } else if (carrier.includes("Cowbell")) {
    return <CowbellLogo />;
  } else if (carrier.includes("Elpha")) {
    return <ElphaSecureLogo />;
  }  else if (carrier.includes("Axis")) {
    return <AxisCapitalLogo />;
  }  else if (carrier.includes("eRisk")) {
    return <ERiskLogo />;     
  } else if (carrier.includes("Measured")) {
    return <MeasuredLogo />;
  } else if (carrier.includes("Node")) {
    return <NodeLogo />;
  } else if (carrier.includes("Tokio")) {
    return <TokioMarineLogo />;
  } else {
    return <CyberLogo />;
  }
};
