import { useQuery } from "@tanstack/react-query";
import { apiFactory } from "../utils/api";
import useAuth from "../auth/useAuth";
import { useSearchParams } from "react-router-dom";

export const useGetAdminQuotes = (admin) => {
  const { getToken } = useAuth();

  const [searchParams] = useSearchParams();
  const searchEmail = searchParams.get("email");

  const getQuotes = async () => {
    const token = await getToken();
    const api = apiFactory(token);
    if (searchEmail) {
      return api.business.getQuotes(encodeURIComponent(searchEmail));
    } else {
      return null;
    }
  };
  return useQuery(["admin_quotes", searchEmail], getQuotes, {
    enabled: admin,
    refetchInterval: false,
    refetchOnWindowFocus: false,
    retry: 1,
  });
};
