import React, { useEffect, useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useNavigate } from "react-router-dom";
import { ActionRow, Next, Header } from "../styles/global";
import Input from "../components/Input";
import Dropdown from "../components/Dropdown";
import Toggle from "../components/Toggle";
import { industryOptions } from "../data/selectOptions";
import { useStore } from "../utils/store";
import { useUpdateUser } from "../hooks/useUpdateUser";

const validationSchema = z.object({
  firstName: z.string().nonempty({ message: "First name is required" }),
  lastName: z.string().nonempty({ message: "Last name is required" }),
  email: z.string().email({ message: "Must be a valid email" }),
  industry: z.string().nonempty({ message: "Industry is required" }),
  company: z.string().nonempty({ message: "Organization name is required" }),
  hasEmployees: z.boolean({
    required_error: "This response is required",
    invalid_type_error: "This response is required",
  }),
  hasCustomers: z.boolean({
    required_error: "This response is required",
    invalid_type_error: "This response is required",
  }),
  hasExternalFunding: z.boolean({
    required_error: "This response is required",
    invalid_type_error: "This response is required",
  }),
});

const Account = () => {
  const start = useStore((state) => state.start);

  const {
    control,
    register,
    getValues,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      firstName: start.firstName,
      lastName: start.lastName,
      email: start.email,
      company: start.company,
      industry: start.industry,
      hasExternalFunding: start.hasExternalFunding,
      hasEmployees: start.hasEmployees,
      hasCustomers: start.hasCustomers,
    },
  });

  const mutation = useUpdateUser(start.email);
  const navigate = useNavigate();

  const resetForm = useCallback(async () => {
    await reset(start);
  }, [reset, start]);

  useEffect(() => {
    resetForm();
  }, [resetForm, start]);

  const saveChanges = async () => {
    if (Object.keys(errors).length !== 0) return;
    const totalData = getValues();
    const payload = JSON.stringify(totalData);
    mutation.mutate(payload, {
      onSuccess: () => {
        navigate("/");
      },
      onError: (error) => {
        console.log(error);
      },
    });
  };

  return (
    <form>
      <Header>Account Information</Header>
      <Input
        label="First Name"
        name="firstName"
        placeholder="e.g. John"
        register={register}
        hasError={errors?.firstName?.message}
        control={control}
        disabled={true}
        tooltip="This should be your legal first name."
      />
      <Input
        label="Last Name"
        name="lastName"
        placeholder="e.g. Smith"
        register={register}
        hasError={errors?.lastName?.message}
        control={control}
        disabled={true}
        tooltip="This should be your legal last name."
      />
      <Input
        label="Email"
        name="email"
        placeholder="e.g. johnsmith@gmail.com"
        register={register}
        hasError={errors?.email?.message}
        control={control}
        tooltip="This is where we will send your quotes."
        disabled={true}
        small={true}
      />
      <Input
        label="Organization Name"
        name="company"
        placeholder="e.g. Smith's Paper Company"
        register={register}
        hasError={errors?.company?.message}
        control={control}
        tooltip="This should be your business' full legal name.  If your organization consists of multiple entities, please use parent company name here and complete the application questions for the sum total of all entities. "
      />
      <Dropdown
        name="industry"
        control={control}
        label="Industry"
        placeholder="Select an industry"
        options={industryOptions}
        start={true}
        tooltip="Knowing your industry gives us context to make appropriate coverage recommendations."
      />
      <Toggle
        label="Does your organization currently have any employees?"
        name="hasEmployees"
        register={register}
        control={control}
        hasError={errors?.hasEmployees?.message}
        start={true}
        tooltip="This should be your business' full legal name.  If your organization consists of multiple entities"
      />
      <Toggle
        label="Does your organization currently have any customers?"
        name="hasCustomers"
        register={register}
        control={control}
        hasError={errors?.hasCustomers?.message}
        start={true}
        tooltip="This should be your business' full legal name.  If your organization consists of multiple entities"
      />
      <Toggle
        label="Has your organization raised over $1 million in outside funding?"
        name="hasExternalFunding"
        register={register}
        control={control}
        hasError={errors?.hasExternalFunding?.message}
        start={true}
        tooltip="This should be your business' full legal name.  If your organization consists of multiple entities"
      />
      <ActionRow padding={true}>
        <Next onClick={handleSubmit(saveChanges)}>Save Changes</Next>
      </ActionRow>
    </form>
  );
};

export default Account;
