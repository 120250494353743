import React from "react";
import styled from "styled-components";
import { useStore } from "../utils/store";
import { isEmpty } from "lodash";

const Step = ({ label, name, active, size, position, step }) => {
  const formErrors = useStore((state) => state.formErrors);
  const updateStep = useStore((state) => state.updateStep);
  const stepArray = useStore((state) => state.stepArray);

  return (
    <Wrapper
      onClick={() => updateStep(step)}
      disabled={!stepArray.includes(step)}
    >
      <Block size={size} position={position} active={active} />
      <Title>{label}</Title>
      {!isEmpty(formErrors[name]) && <Dot />}
    </Wrapper>
  );
};

const handleBlockSize = (size) => {
  switch (size) {
    case "big":
      return "80px";
    case "small":
      return "40px";
    case "medium":
      return "60px";
    default:
      return "30px";
  }
};

const handleBlockPosition = (position) => {
  switch (position) {
    case "top":
      return "20px 20px 0 0";
    case "bottom":
      return "0 0 20px 20px";
    default:
      return "0";
  }
};

const Wrapper = styled.button`
  margin-left: 47px;
  display: flex;
  flex-direction: row;
  border: none;
  background: none;
  align-items: center;

  &:hover {
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  }
`;

const Dot = styled.div`
  height: 8px;
  width: 8px;
  border-radius: 30px;
  background-color: ${(props) => props.theme.red};
  margin: auto;
  margin-left: 10px;
`;

const Block = styled.div`
  margin: 0 20px 2px 0;
  min-height: 30px;
  height: ${(props) => handleBlockSize(props.size)};
  width: 30px;
  background-color: ${(props) =>
    props.active ? props.theme.blue : props.theme.grey};
  border-radius: ${(props) => handleBlockPosition(props.position)};
`;

const Title = styled.h2`
  color: ${(props) => props.theme.white};
  font-size: 18px;
  font-weight: 500;
  align-items: center;
  display: flex;
  margin: 0;
`;

export default Step;
