export const theme = {
  blue: "#01a6e2",
  darkblue: "#095075",
  white: "#fff",
  almostwhite: "#f7f7f7",
  grey: "#f2f2f2",
  background: "#F7FBFF",
  black: "#000",
  offblack: "#4E5357",
  lightblack: "#69696A",
  lighterblack: "#8E8E90",
  darkgrey: "#8d8d8d",
  placeholder: "#B6B6B6",
  red: "#fd796d",
  orange: "#ffb417",
  cta: "#FFC63D",
  yellow: "#FFDF71",
  hovercta: "#FFD864",
  hoverbutton: "#3F8ABA",
  disabledbutton: "#76B6DF",
  helpbutton: "#4C708A",
  toolTipBackground: "#C4DFF1",
  ready: "#D2EBBE",
  pending: "#FFC63D",
  missing: "#FFC63D",
};
