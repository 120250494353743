import { useMutation } from "@tanstack/react-query";
import { apiFactory } from "../utils/api";

export const useStartApplication = () => {
  const startApplication = async (payload) => {
    const api = apiFactory();
    return api.commercial.startApplication(payload);
  };
  return useMutation(startApplication);
};
