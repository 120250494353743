import React from "react";
import styled from "styled-components";

const ReviewItem = ({ name, value, yesNo, row }) => {
  const showYesNo = (value) => {
    if (value === true) {
      return "Yes";
    } else if (value === false) {
      return "No";
    } else {
      return "";
    }
  };

  const valueCheck = (value) => {
    if (value === "" || value === undefined || value === null) {
      return <EmptyValue>{value}</EmptyValue>;
    } else {
      if (yesNo) {
        return <ItemValue>{showYesNo(value)}</ItemValue>;
      } else {
        return <ItemValue>{value}</ItemValue>;
      }
    }
  };

  return (
    <>
      {yesNo || row ? (
        <Row>
          <ItemName>{name}</ItemName>
          {valueCheck(value)}
        </Row>
      ) : (
        <>
          <ItemName>{name}</ItemName>
          {valueCheck(value)}
        </>
      )}
    </>
  );
};

const EmptyValue = styled.p`
  background: rgba(255, 198, 61, 0.5);
  margin: 0;
  min-width: 22px;
`;

const ItemName = styled.p`
  font-weight: 500;
  color: black;
  margin: 0;
`;

const ItemValue = styled.p`
  color: ${(props) => props.theme.lightblack};
  margin: 0;
  min-width: 22px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 14px;
`;

export default ReviewItem;
