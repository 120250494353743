import { useAuth0 } from "@auth0/auth0-react";
import { isEmpty } from "lodash";

const useAuth = () => {
  const auth0 = useAuth0();
  const roles = auth0.isAuthenticated && auth0.user ? auth0.user : {};
  const sandbox =
    window.location.hostname.includes("sandbox") ||
    window.location.hostname.includes("localhost");

  const getAdmin = () => {
    if (sandbox) {
      const admin = (!isEmpty(roles) && roles.hasOwnProperty("sandbox-especialty/roles") )
        ? roles["sandbox-especialty/roles"][0] === "admin"
        : false;
      return admin;
    } else {
      const admin = (!isEmpty(roles) && roles.hasOwnProperty("especialty/roles") )
        ? roles["especialty/roles"][0] === "admin"
        : false;
      return admin;
    }
  };

  const email = roles.email;

  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
  const getToken = async () => {
    if (!auth0.isAuthenticated){
      return null;
    }
    try {
      const token = await auth0.getAccessTokenSilently({
        audience: audience,
      });
      return token;
    } catch (e) {
      console.log(e.message);
    }
  };

  return {
    ...auth0,
    getToken,
    roles,
    email,
    getAdmin,
  };
};

export default useAuth;
