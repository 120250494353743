import React, { useEffect, useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import useAuth from "../../auth/useAuth";
import Input from "../../components/Input";
import Dropdown from "../../components/Dropdown";
import { orgOptions } from "../../data/selectOptions";
import { Header, Next, ActionRow } from "../../styles/global";
import Address from "../../components/Address";
import { useStore } from "../../utils/store";
import { useCreateCyberQuote } from "../../hooks/useCreateCyberQuote";
import { useUpdateApplication } from "../../hooks/useUpdateApplication";
import SearchNaics from "../../components/SearchNaics";
import WebAdd from "../../components/WebAdd";
import { useGetUser } from "../../hooks/useGetUser";
import { useGetApplication } from "../../hooks/useGetApplication";
import { useParams } from "react-router-dom";
import { omit, isEmpty } from "lodash";

const date = new Date();
const year = date.getFullYear();

const validationSchema = z.object({
  businessPhone: z.string().nonempty({ message: "Business phone is required" }),
  organizationAddress: z
    .object({
      street: z.string().nonempty({ message: "Business address is required" }),
      city: z.string().nonempty({ message: "City is required" }),
      state: z.string().nonempty({ message: "State is required" }),
    })
    .or(z.string().nonempty({ message: "Business address is required" })),
  employeeCount: z.string().nonempty({ message: "Employee count is required" }),
  yearEstablished: z
    .string()
    .nonempty({ message: "Year established is required" })
    .max(4)
    .refine((val) => val <= year, "Must be a valid year"),
  annualRevenue: z
    .number()
    .min(1, { message: "Annual sales is required" })
    .or(z.string().nonempty({ message: "Annual sales is required" })),
  organizationType: z
    .string()
    .nonempty({ message: "Organization type is required" }),
  primaryOperations: z
    .string()
    .nonempty({ message: "Primary operations is required" }),
});

const BusinessInfo = () => {
  const form = useStore((state) => state.form);
  const regex = new RegExp("^[0-9,$]+");

  const {
    control,
    register,
    watch,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      domainPrim: form.domainPrim,
      businessPhone: form.businessPhone,
      organizationAddress: {
        street: form.organizationAddress.street,
        city: form.organizationAddress.city,
        state: form.organizationAddress.state,
        zipCode: form.organizationAddress.zipCode,
      },
      employeeCount: form.employeeCount,
      yearEstablished: regex.exec(form.yearEstablished),
      annualRevenue: form.annualRevenue,
      organizationType: form.organizationType,
      primaryOperations: form.primaryOperations,
    },
  });

  const [display, setDisplay] = useState(false);
  const [requote, setRequote] = useState(false);
  const admin = useStore((state) => state.admin);
  const step = useStore((state) => state.step);
  const searchEmail = useStore((state) => state.searchEmail);
  const updateStep = useStore((state) => state.updateStep);
  const stepArray = useStore((state) => state.stepArray);
  const updateStepArray = useStore((state) => state.updateStepArray);
  const updateForm = useStore((state) => state.updateForm);
  const updateFormErrors = useStore((state) => state.updateFormErrors);
  const updateFullAddress = useStore((state) => state.updateFullAddress);
  const updateAnnualRev = useStore((state) => state.updateAnnualRev);
  const quoted = useStore((state) => state.quoted);
  const postMutation = useCreateCyberQuote();
  const putMutation = useUpdateApplication(form.id);
  const { email } = useAuth();
  const { appId } = useParams();

  const existingApp = appId !== 0 && appId !== undefined;
  const { data: userData } = useGetUser(email);
  const { data: applicationData } = useGetApplication(
    email,
    existingApp && !admin
  );
  const { data: adminApplicationData } = useGetApplication(
    searchEmail,
    existingApp
  );

  useEffect(() => {
    const subscription = watch((value) => updateForm(value));
    return () => subscription.unsubscribe();
  }, [watch, updateForm]);
  const resetForm = useCallback(async () => {
    if (userData && !admin) {
      setDisplay(true);
    }
    if (admin) {
      loadFormInfo(adminApplicationData);
    } else if (userData) {
      loadFormInfo(applicationData);
    }
  }, [reset, userData, admin, applicationData, adminApplicationData]);

  const loadFormInfo = async (appData) => {
    const accountInfo = !admin ? ({
      contactFirstName: userData.firstName,
      contactLastName: userData.lastName,
      contactEmail: userData.email,
      organizationName: userData.company,
    } ) : {
      //no opp?
    }
    const newData = { ...appData, ...accountInfo };
    setDisplay(false);
    await reset(newData);
    if (appData && appId !== 0) { 
      if (appData.organizationAddress) {
        await updateFullAddress(
          `${appData.organizationAddress.street} ${appData.organizationAddress.city}, ${appData.organizationAddress.state} ${appData.organizationAddress.zipCode}`
        );
      }
      if (appData.annualRevenue) {
        await updateAnnualRev(appData.annualRevenue);
      }

      if (!isEmpty(quoted) && quoted.id === parseInt(appId)) {
        await setRequote(true);
      }
      setDisplay(true);
    }
    if (admin && appId === undefined) {
      setDisplay(true);
    }
    if (!appData) {
      await reset(form);
      setDisplay(true);
    }
  };

  useEffect(() => {
    resetForm();
  }, [resetForm]);

  const handleClick = async () => {
    if (Object.keys(errors).length !== 0) return;
    reset((formValues) => ({
      ...formValues,
    }));
    updateFormErrors({ businessInfo: errors });
    onSubmit();
  };

  const onSubmit = async () => {
    const newForm =
      form.domainPrim[0]?.domainPrim === "" ? omit(form, "domainPrim") : form;
    const payload = JSON.stringify(newForm);
    if (form.id === 0) {
      postMutation.mutate(payload, {
        onSuccess: (data) => {
          navigateForward();
          updateForm({ id: data });
        },
        onError: (error) => {
          console.log(error);
        },
      });
    } else {
      putMutation.mutate(payload, {
        onSuccess: () => {
          navigateForward();
        },
        onError: (error) => {
          console.log(error);
        },
      });
    }
  };

  const skip = () => {
    updateFormErrors({ businessInfo: errors });
    onSubmit();
    navigateForward();
  };

  const navigateForward = () => {
    if (stepArray.indexOf(step) === -1) {
      updateStepArray(1);
    }
    updateStep(2);
  };

  return (
    <form>
      <Header>Business Information</Header>
      {display && (
        <>
          {admin && (
            <>
              <Input
                label="First Name"
                name="contactFirstName"
                placeholder="e.g. John"
                register={register}
                hasError={errors?.firstName?.message}
                control={control}
                tooltip="This should be your legal first name."
              />
              <Input
                label="Last Name"
                name="contactLastName"
                placeholder="e.g. Smith"
                register={register}
                // hasError={errors?.lastName?.message}
                control={control}
                tooltip="This should be your legal last name."
              />
              <Input
                label="Organization Name"
                name="organizationName"
                placeholder="e.g. Smith's Paper Company"
                register={register}
                // hasError={errors?.lastName?.message}
                control={control}
                tooltip="This should be your business' full legal name.  If your organization consists of multiple entities, please use parent company name here and complete the application questions for the sum total of all entities."
              />
              <Input
                label="Email"
                name="contactEmail"
                placeholder="e.g. johnsmith@gmail.com"
                register={register}
                hasError={errors?.contactEmail?.message}
                control={control}
                tooltip="This is where we will send your quotes."
                small={true}
              />
            </>
          )}
          <Address
            name="organizationAddress"
            control={control}
            register={register}
            hasError={errors?.organizationAddress?.street?.message}
            tooltip="This is the address where your business is located."
          />
          <Input
            label="Unit"
            name="organizationAddress.unit"
            placeholder="e.g. A120"
            register={register}
            control={control}
          />
          <Input
            label="Phone Number"
            name="businessPhone"
            placeholder="e.g. (123) 312-2131"
            pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
            formatter="phone"
            register={register}
            hasError={errors?.businessPhone?.message}
            control={control}
            tooltip="This is the phone number we will use to contact you, if necessary."
            // disabled={requote}
          />
          <WebAdd
            register={register}
            control={control}
            errors={
              errors &&
              errors.domainPrim &&
              errors.domainPrim[0] &&
              errors.domainPrim[0].message
            }
          />
          <Input
            label="Employee Count"
            name="employeeCount"
            placeholder="e.g. 23"
            register={register}
            formatter="number"
            hasError={errors?.employeeCount?.message}
            control={control}
            tooltip="This should include all employees - owners, full-time, part-time, and even yourself!"
          />
          <Input
            label="Year Established"
            name="yearEstablished"
            placeholder="e.g. 1881"
            register={register}
            maxLength={4}
            hasError={errors?.yearEstablished?.message}
            control={control}
            tooltip='To ensure your coverage is comprehensive and continuous, we need to know when your business started.  This is relevant for what is referred to in insurance as "coverage of prior acts."'
          />
          <Input
            label="Annual Sales"
            name="annualRevenue"
            placeholder="e.g. $100,000"
            formatter="currency"
            register={register}
            hasError={errors?.annualRevenue?.message}
            control={control}
            pattern="^$d{1,3}(,d{3})*(.d+)?$"
            tooltip="Provide your estimated revenue for the next 12 months.  Keep in mind, unlike with investor forecasts, you are not trying to impress the underwriter so be realistic, not optimistic. Even if you are pre-revenue, please put at least $1."
          />
          <Dropdown
            name="organizationType"
            control={control}
            autoComplete="off"
            label="Organization Type"
            placeholder="Select your organization type"
            options={orgOptions}
            tooltip="This helps us identify your corporate form for underwriting. "
          />
          <SearchNaics
            name="primaryOperations"
            control={control}
            label="Primary Operations"
            tooltip="This is your NAIC code, which is a Federal business classification type.  If you don't know it (most business owners don't!), we can help you look it up. "
          />
          <ActionRow>
            <Next onClick={handleSubmit(handleClick)}>Next</Next>
            {Object.keys(errors).length !== 0 && (
              <Next onClick={skip}>Skip</Next>
            )}
          </ActionRow>
        </>
      )}
    </form>
  );
};

export default BusinessInfo;
