import { useQuery } from "@tanstack/react-query";
import { apiFactory } from "../utils/api";

export const useSearchNaics = (searchTerm) => {
  const searchNaics = () => {
    const api = apiFactory();
    return api.commercial.searchNaics(searchTerm);
  };
  return useQuery(["search_naics", searchTerm], searchNaics, {
    refetchInterval: false,
    refetchOnWindowFocus: false,
    retry: 1,
  });
};
