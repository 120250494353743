import { useMutation } from "@tanstack/react-query";
import { apiFactory } from "../utils/api";
import useAuth from "../auth/useAuth";

export const useUpdateLob = (email) => {
  const { getToken } = useAuth();

  const updateLob = async (lob) => {
    const token = await getToken();
    const api = apiFactory(token);

    return api.business.updateLob(encodeURIComponent(email), lob);
  };
  return useMutation(updateLob);
};
