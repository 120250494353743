import React, { useState } from "react";
import Select from "react-select";
import { useSearchNaics } from "../hooks/useSearchNaics";
import { useController } from "react-hook-form";
import styled from "styled-components";
import { theme } from "../styles/theme";
import { selectStyles, Line, StyledTooltip, ErrorText } from "../styles/global";
import { debounce } from "lodash";
import { useStore } from "../utils/store";

const SearchNaics = ({ name, label, control, tooltip }) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  const [showTooltip, setShowTooltip] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [naics, setNaics] = useState(null);
  const form = useStore((state) => state.form);
  const updateForm = useStore((state) => state.updateForm);

  const setSearchTermDebounced = React.useRef(
    debounce((searchTerm) => setSearchTerm(searchTerm), 500)
  ).current;

  const handleChangeNaics = (selectedItem) => {
    if (selectedItem && selectedItem.code) {
      updateForm({
        ...form,
        primaryOperations: `${selectedItem.code}: ${selectedItem.name}`,
      });
      field.onChange(`${selectedItem.code}: ${selectedItem.name}`);
    } else {
      updateForm({ ...form, primaryOperations: "" });
      field.onChange("");
    }
    setShowTooltip(false);
    setNaics(selectedItem);
  };

  const handleInputChangeNaics = (inputText, event) => {
    if (event.action !== "input-blur" && event.action !== "menu-close") {
      setSearchTermDebounced(inputText);
    }
  };

  const {
    status: searchStatus,
    data: naicsSearchResults,
    isLoading,
  } = useSearchNaics(searchTerm);

  const filteredResults =
    !isLoading && naicsSearchResults ? naicsSearchResults : [];

  const handleOptionLabel = (option) => {
    return `${option.code}: ${option.name}`;
  };

  const handleFocus = () => {
    setShowTooltip(true);
  };

  const handleBlur = () => {
    setShowTooltip(false);
  };

  return (
    <Container>
      <Wrapper id={name} hasError={error}>
        <Label>{label}</Label>
        <StyledSelect
          key={field.value}
          noOptionsMessage={() => "No results found"}
          isClearable={true}
          isLoading={searchStatus === "loading"}
          value={naics}
          labelKey={filteredResults.name}
          valueKey={filteredResults.code}
          options={filteredResults}
          getOptionLabel={(option) => handleOptionLabel(option)}
          onChange={handleChangeNaics}
          onInputChange={handleInputChangeNaics}
          placeholder="e.g. 45432 Soybean Farming"
          styles={selectStyles}
          name={name}
          onBlur={handleBlur}
          onFocus={handleFocus}
          hasError={error}
          defaultInputValue={field.value}
        />
        {error && (
          <StyledErrorText>Primary operations is required</StyledErrorText>
        )}
      </Wrapper>
      {showTooltip && tooltip && (
        <>
          <Line haserror={error} />
          <StyledTooltip haserror={error}>{tooltip}</StyledTooltip>
        </>
      )}
    </Container>
  );
};

const StyledErrorText = styled(ErrorText)``;

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-bottom: ${(props) => (props.hasError ? "10px" : "30px")};
`;

const Label = styled.label`
  padding: 0 16px 8px 16px;
  color: ${theme.offblack};
  font-size: 16px;
  weight: 500;
  overflow-wrap: break-word;
  max-width: 350px;
  text-align: left;
`;

const StyledSelect = styled(Select)`
  width: 430px;
  border-radius: 8px;
  border: ${(props) =>
    props.hasError
      ? `1px solid ${props.theme.red}`
      : `1px solid ${props.theme.white}`};

  &:focus {
    border: ${(props) =>
      props.hasError
        ? `1px solid ${props.theme.red}`
        : `1px solid ${props.theme.white}`};
  }

  &:hover {
    border: ${(props) =>
      props.hasError
        ? `1px solid ${props.theme.red}`
        : `1px solid ${props.theme.white}`};
  }
`;

export default SearchNaics;
