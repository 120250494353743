import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useStartApplication } from "../../hooks/useStartApplication";
import { ActionRow, Next, Header } from "../../styles/global";
import Input from "../../components/Input";
import Dropdown from "../../components/Dropdown";
import Toggle from "../../components/Toggle";
import { industryOptions } from "../../data/selectOptions";
import { useStore } from "../../utils/store";
import { useAuth0 } from "@auth0/auth0-react";
import { useGetUser } from "../../hooks/useGetUser";
import useAuth from "../../auth/useAuth";
import { useNavigate } from "react-router-dom";
import Textarea from "../../components/Textarea";



const validationSchema = z.object({
  firstName: z.string().nonempty({ message: "First name is required" }),
  lastName: z.string().nonempty({ message: "Last name is required" }),
  email: z.string().trim().email({ message: "Must be a valid email" }),
  industry: z.string().nonempty({ message: "Industry is required" }),
  company: z.string().nonempty({ message: "Organization name is required" }),
  hasEmployees: z.boolean({
    required_error: "This response is required",
    invalid_type_error: "This response is required",
  }),
  hasCustomers: z.boolean({
    required_error: "This response is required",
    invalid_type_error: "This response is required",
  }),
  hasExternalFunding: z.boolean({
    required_error: "This response is required",
    invalid_type_error: "This response is required",
  }),
});

const GetStarted = () => {
  const {
    control,
    register,
    getValues,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      industry: "",
    },
  });

  const { email } = useAuth();
  const { data: userData } = useGetUser(email);
  const navigate = useNavigate();

  const updateStep = useStore((state) => state.updateStep);
  const step = useStore((state) => state.step);

  const mutation = useStartApplication();
  const { loginWithRedirect } = useAuth0();

  const initializeApp = async () => {
    if (Object.keys(errors).length !== 0) return;
    const totalData = getValues();
    totalData.email=totalData.email.trim();
    const payload = JSON.stringify(totalData);
    mutation.mutate(payload, {
      onSuccess: () => {
        if (userData) {
          navigate("/");
        } else {
          loginWithRedirect({
            authorizationParams: {
              login_hint: totalData.email,
              screen_hint: "signup",
            },
            appState: {
              returnTo: window.location.origin,
            },
          });
        }

        updateStep(1);
      },
      onError: (error) => {
        console.log(error);
      },
    });
  };

  return (
    <form>
      {step === 1 && (
        <>
          <Header>Basic Information</Header>
          <Input
            label="First Name"
            name="firstName"
            placeholder="e.g. John"
            register={register}
            hasError={errors?.firstName?.message}
            control={control}
            tooltip="This should be your legal first name."
          />
          <Input
            label="Last Name"
            name="lastName"
            placeholder="e.g. Smith"
            register={register}
            hasError={errors?.lastName?.message}
            control={control}
            tooltip="This should be your legal last name."
          />
          <Input
            label="Email"
            name="email"
            placeholder="e.g. johnsmith@gmail.com"
            register={register}
            hasError={errors?.email?.message}
            control={control}
            tooltip="This is where we will send your quotes."
            // disabled={requote}
            small={true}
          />
          <Input
            label="Organization Name"
            name="company"
            placeholder="e.g. Smith's Paper Company"
            register={register}
            hasError={errors?.company?.message}
            control={control}
            tooltip="This should be your business' full legal name.  If your organization consists of multiple entities, please use parent company name here and complete the application questions for the sum total of all entities. "
          />
          <Dropdown
            name="industry"
            control={control}
            label="Industry"
            placeholder="Select an industry"
            options={industryOptions}
            start={true}
            tooltip="Knowing your industry gives us context to make appropriate coverage recommendations."
          />
          <Toggle
            label="Does your organization currently have any employees?"
            name="hasEmployees"
            register={register}
            control={control}
            hasError={errors?.hasEmployees?.message}
            start={true}
            tooltip="Select yes if you have W2 employees, not including yourself.  If you're in NY, CA, or other employment-friendly states, 1099 contractors may be considered employees so be sure to let us know when you apply if you have any of those as well."
          />
          <Toggle
            label="Does your organization currently have any customers?"
            name="hasCustomers"
            register={register}
            control={control}
            hasError={errors?.hasCustomers?.message}
            start={true}
            tooltip="Select yes if you already provide goods or services to customers."
          />
          <Toggle
            label="Does your organization have a Board of Directors?"
            name="hasExternalFunding"
            register={register}
            control={control}
            hasError={errors?.hasExternalFunding?.message}
            start={true}
            tooltip="Select yes if you have a Board of Directors, as they have obligations to external stakeholders that create specific exposures."
          />
          <Textarea
            label="Is there anything additional we should know?"
            name="comments"
            register={register}
            control={control}
            maxLength={1024}
            hasError={errors?.comments?.message}
            start={true}
            tooltip="Please provide any comments or other information to clarify what you are looking for, unique operating characteristics you may have, and how we may be helpful."
          />
          <ActionRow padding={true}>
            <Next onClick={handleSubmit(initializeApp)}>
              Next: Create Account
            </Next>
          </ActionRow>
        </>
      )}
    </form>
  );
};

export default GetStarted;
