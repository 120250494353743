/* eslint-disable */
import React, { useState } from "react";
import {
  ModalContentContainer,
  Header,
  Next,
  ModalActionRow,
  ActionContainer,
} from "../../styles/global";
import styled from "styled-components";
import ConfettiFun from "./ConfettiFun";
import { useSelectQuote } from "../../hooks/useSelectQuote";
import Modal from "../../components/Modal";

const SelectModal = ({ data, closeModal, modalIsOpen, appId }) => {
  const handleShow = () => {
    closeModal();
  };

  const mutation = data.id && useSelectQuote(appId, data.id);

  const selectQuote = async () => {
    if (data.id) {
      mutation.mutate({});
      window.location.href = "https://especialty.com/quote-selected/";
    }
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      closeModal={handleShow}
      label="select-quote"
      title="Select Quote"
    >
      <ModalContentContainer>
        <ConfettiFun />
        <Title>Hooray! - you have selected a cyber insurance policy.</Title>
        <ConfirmationText>
          By clicking 'I Agree', your agent will be notified of your desire to
          bind coverage, and he/she will provide you with an invoice via email.
          You acknowledge that no coverage is in place until you receive a
          written binder or policy.
        </ConfirmationText>
      </ModalContentContainer>
      <ActionContainer>
        <ModalActionRow>
          <Next onClick={closeModal}>Back</Next>
          <Next onClick={selectQuote}>I Agree</Next>
        </ModalActionRow>
      </ActionContainer>
    </Modal>
  );
};

const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
`;

const Title = styled(Header)`
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 20px;
`;

const ConfirmationText = styled.p`
  font-size: 16px;
`;

const Checkbox = styled.input`
  margin-right: 20px;
  width: 50px;
`;

export default SelectModal;
