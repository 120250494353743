import React, { useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import Toggle from "../../components/Toggle";
import { Header, Next, ActionRow } from "../../styles/global";
import { useStore } from "../../utils/store";
import { useUpdateApplication } from "../../hooks/useUpdateApplication";
import Input from "../../components/Input";
import { omit } from "lodash";

const validationSchema = z.object({
  uwMfa: z.boolean({
    required_error: "This response is required",
    invalid_type_error: "This response is required",
  }),
  uwMfaNetwork: z.boolean({
    required_error: "This response is required",
    invalid_type_error: "This response is required",
  }),
  uwMfaEmail: z.boolean({
    required_error: "This response is required",
    invalid_type_error: "This response is required",
  }),
  uwMfaRequired: z.boolean({
    required_error: "This response is required",
    invalid_type_error: "This response is required",
  }),
  lossWarranty: z.boolean({
    required_error: "This response is required",
    invalid_type_error: "This response is required",
  }),
  currentCoverage: z.boolean({
    required_error: "This response is required",
    invalid_type_error: "This response is required",
  }),
  contractRequirement: z.boolean({
    required_error: "This response is required",
    invalid_type_error: "This response is required",
  }),
  lossClaims: z.boolean({
    required_error: "This response is required",
    invalid_type_error: "This response is required",
  }),
});

const Cybersecurity = () => {
  const form = useStore((state) => state.form);

  const {
    control,
    register,
    watch,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      claimsIncident: form.claimsIncident,
    },
  });

  const step = useStore((state) => state.step);
  const stepArray = useStore((state) => state.stepArray);
  const updateStepArray = useStore((state) => state.updateStepArray);
  const updateForm = useStore((state) => state.updateForm);
  const updateStep = useStore((state) => state.updateStep);
  const updateFormErrors = useStore((state) => state.updateFormErrors);

  const mutation = useUpdateApplication(form.id);

  useEffect(() => {
    const subscription = watch((value) => updateForm(value));
    return () => subscription.unsubscribe();
  }, [watch]);

  const resetAsyncForm = useCallback(async () => {
    await reset(form);
  }, [reset]);

  useEffect(() => {
    resetAsyncForm();
  }, [resetAsyncForm]);

  const watchContractRequirement = watch("contractRequirement");
  const watchCurrentCoverage = watch("currentCoverage");
  const watchLossClaims = watch("lossClaims");

  const handleClick = () => {
    if (Object.keys(errors).length !== 0) return;
    reset((formValues) => ({
      ...formValues,
    }));
    updateFormErrors({ policyQuestions: errors });
    onSubmit();
  };

  const onSubmit = async () => {
    const newForm =
      form.domainPrim[0]?.domainPrim === "" ? omit(form, "domainPrim") : form;
    const payload = JSON.stringify(newForm);
    mutation.mutate(payload, {
      onSuccess: () => {
        navigateForward();
      },
      onError: (error) => {
        console.log(error);
      },
    });
  };

  const skip = () => {
    updateFormErrors({ policyQuestions: errors });
    onSubmit();
    navigateForward();
  };

  const navigateForward = () => {
    if (stepArray.indexOf(step) === -1) {
      updateStepArray(3);
    }
    updateStep(4);
  };

  return (
    <form>
      <Header>Cyber Questions</Header>
      <Toggle
        label="Do you have any Multi-Factor Authentication (MFA)?"
        name="uwMfa"
        register={register}
        control={control}
        hasError={errors?.uwMfa?.message}
        tooltip="This means anywhere in your organization.  Common MFA usage includes email, VPN or other remote access"
      />
      <Toggle
        label="Is MFA enabled for remote network access?"
        name="uwMfaNetwork"
        register={register}
        control={control}
        hasError={errors?.uwMfaNetwork?.message}
        tooltip="Remote network access refers to VPN or similar.  If you do not have a remote network, please select something something something."
      />
      <Toggle
        label="Is MFA enabled for email access?"
        name="uwMfaEmail"
        register={register}
        control={control}
        hasError={errors?.uwMfaEmail?.message}
        tooltip="If you use Outlook or Gmail, you probably have MFA enabled when you initially login.  We strongly recommend setting up email MFA, if you do not use already. "
      />
      <Toggle
        label="Do you require MFA for all external access to your network?"
        name="uwMfaRequired"
        register={register}
        control={control}
        hasError={errors?.uwMfaRequired?.message}
        tooltip="Network here is used broadly. Think: individual users' desktops or locations/servers where multiple users can share files. The concern is that these connections, if compromised, could provide a criminal with access to the entire network of individual desktops,  servers, or cloud services."
      />
      <Toggle
        label="Are you aware of any circumstance, incident, or notice that could give rise to a cyber claim?"
        name="lossWarranty"
        register={register}
        control={control}
        hasError={errors?.lossWarranty?.message}
        tooltip="It is uncommon for the answer to be yes.  If yes, and you have current coverage in place, this should be reported to your carrier ASAP.  Typically, insurance will not cover incidents that occur prior to policy inception."
      />
      <Toggle
        label="Do you currently have cybersecurity coverage?"
        name="currentCoverage"
        register={register}
        control={control}
        hasError={errors?.currentCoverage?.message}
        tooltip="If so, awesome!  We can help you get multiple new quotes or simply renew it in a jiffy.  If not, we're glad you're here now!"
      />
      {watchCurrentCoverage && (
        <Input
          label="Expiration date of current policy"
          name="expirationDate"
          formatter="date"
          register={register}
          hasError={errors?.expirationDate?.message}
          control={control}
        />
      )}
      <Toggle
        label="Are you interested in cybersecurity coverage in order to satisfy a contractual requirement?"
        name="contractRequirement"
        register={register}
        control={control}
        hasError={errors?.contractRequirement?.message}
        tooltip="Knowing this helps us meet your deadlines and ensure our policy recommendations meet your contractual requirements."
      />
      {watchContractRequirement && (
        <Input
          label="Limit required by contract"
          name="contractLimit"
          placeholder="e.g. $1,000"
          formatter="currency"
          register={register}
          control={control}
          pattern="^$d{1,3}(,d{3})*(.d+)?$"
        />
      )}
      <Toggle
        label="Have you experienced a cyber claim or incident?"
        name="lossClaims"
        register={register}
        control={control}
        hasError={errors?.lossClaims?.message}
        tooltip="We're sorry to hear that, but good news is that it is not a dealbreaker for subsequent coverage!  We all know problems happen- that's why we buy insurance!  We just need to understand what actions have been taken since the claim/incident so we can advocate effectively for you."
      />
      {watchLossClaims && (
        <Input
          label="Claims Incident"
          name="claimsIncident"
          placeholder="Explain what happened."
          register={register}
          control={control}
        />
      )}
      <ActionRow>
        <Next onClick={() => updateStep(2)}>Back</Next>
        <Next onClick={handleSubmit(handleClick)}>Next</Next>
        {Object.keys(errors).length !== 0 && <Next onClick={skip}>Skip</Next>}
      </ActionRow>
    </form>
  );
};

export default Cybersecurity;
