import { useMutation } from "@tanstack/react-query";
import { apiFactory } from "../utils/api";
import useAuth from "../auth/useAuth";

export const useSelectQuote = (appId, quoteId) => {
  const { getToken } = useAuth();

  const selectQuote = async () => {
    const token = await getToken();
    const api = apiFactory(token);
     if (token) {
      return api.business.selectQuote(appId, quoteId);
      } else {
      return api.commercial.selectQuoteFromML(appId, quoteId);  
    }
  };
  return useMutation(selectQuote);
};
