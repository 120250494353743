import React from "react";
import styled from "styled-components";
import ReactModal from "react-modal";
import { Next } from "../styles/global";
import Close from "../images/close.svg";

ReactModal.setAppElement("#root");

const Modal = ({
  isOpen,
  closeModal,
  label,
  title,
  onSubmit,
  loading,
  children,
  buttonText,
  disabled = false,
  renderCloseButton = true,
}) => {
  return (
    <ReactModal
      isOpen={isOpen}
      style={ModalStyles}
      onRequestClose={closeModal}
      contentLabel={label}
      shouldCloseOnOverlayClick={true}
    >
      <ModalFormContainer
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit && onSubmit(e);
        }}
      >
        <ModalTopBar>
          <ModalTitle data-cy={`${label}-header`}>{title}</ModalTitle>
          {renderCloseButton && (
            <CloseModal onClick={closeModal}>
              <Icon src={Close} alt="Close Modal" />
            </CloseModal>
          )}
        </ModalTopBar>
        {children}
        {onSubmit && (
          <ActionContainer>
            <Next type="submit" disabled={loading || disabled}>
              <span>{buttonText || "Save"}</span>
            </Next>
          </ActionContainer>
        )}
      </ModalFormContainer>
    </ReactModal>
  );
};

const Icon = styled.img`
  height: 16px;
`;

const ModalTopBar = styled.div`
  background: ${(props) => props.theme.darkblue};
  padding: 16px 24px;
  margin-top: 0;
  margin-bottom: 0;
  display: inline-block;
`;

const CloseModal = styled.button`
  display: inline-block;
  float: right;
  background: ${(props) => props.theme.darkblue};
  border: ${(props) => props.theme.darkblue};
  margin-top: 2px;
  margin-right: -2px;
  cursor: pointer;
`;

const ModalTitle = styled.h2`
  color: white;
  font-size: 20px;
  display: inline-block;
  margin: 0px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 22px;
`;

const ModalFormContainer = styled.form`
  position: relative;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

const ActionContainer = styled.div`
  text-align: center;
  align-items: center;
  justify-content: center;
  padding-top: 16px;
  padding-bottom: 16px;
  margin-top: 10px;
  border-top: ${(props) => `2px solid ${props.theme.lightgrey}`};
`;

const ModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-45%, -50%)",
    maxWidth: "1200px",
    display: "flex",
    flexDirection: "column",
    padding: "0px",
    position: "fixed",
    border: "none",
    borderRadius: "8px",
    boxShadow: "1px 3px 17px -5px rgba(0, 0, 0, 0.25)",
  },
  overlay: {
    zIndex: 10,
    backgroundColor: "rgba(0, 0, 0, 0.33)",
  },
};

export default Modal;
