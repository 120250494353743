import React from "react";
import BusinessInfo from "./BusinessInfo";
import TypeBusiness from "./TypeBusiness";
import Cybersecurity from "./Cybersecurity";
import Insurance from "./Insurance";
import Review from "./Review";
import { useStore } from "../../utils/store";

const Application = () => {
  const step = useStore((state) => state.step);

  return (
    <>
      {step === 1 && <BusinessInfo />}
      {step === 2 && <TypeBusiness />}
      {step === 3 && <Cybersecurity />}
      {step === 4 && <Insurance />}
      {step === 5 && <Review />}
    </>
  );
};

export default Application;
