import React, { useEffect } from "react";
import { useFieldArray } from "react-hook-form";
import styled from "styled-components";
import Plus from "../images/plus.svg";
import Trash from "../images/trash.svg";
import Input from "./Input";
import { useStore } from "../utils/store";

const WebAdd = ({ register, control, errors }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "domainPrim",
  });
  const form = useStore((state) => state.form);

  useEffect(() => {
    // console.log(fields, "fields");
    // console.log(form.domainPrim, "form domain");
  }, []);

  return (
    <>
      {form.domainPrim.map((field, index) => (
        <div key={field.id}>
          <Row>
            <Input
              index={index}
              formatter="array"
              label="Website"
              control={control}
              name={`domainPrim.${index}`}
              register={register}
              placeholder="e.g. www.smithspapercompany.com"
              hasError={errors}
              tooltip={
                index === 0 &&
                "Enter your website starting with 'www.'  If you have multiple domains, put your primary domain in first."
              }
            />
            {index !== 0 && (
              <DeleteIcon
                alt="delete-icon"
                type="button"
                onClick={() => remove(index)}
              />
            )}
          </Row>
        </div>
      ))}
      <AddContainer>
        <PlusIcon
          alt="plus-icon"
          type="button"
          onClick={() => append({ domainPrim: "" })}
        />

        <WebAddText>
          Does your business have multiple websites? Click the + to add more.
        </WebAddText>
      </AddContainer>
    </>
  );
};

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const WebAddText = styled.p`
  color: ${(props) => props.theme.offblack};
  font-size: 13px;
  margin: 7px 0 24px 7px;
`;

const PlusIcon = styled.button`
  background-image: url(${Plus});
  background-repeat: no-repeat;
  background-color: ${(props) => props.theme.background};
  border: none;
  height: 25px;
  width: 25px;
  margin-left: 20px;
  &:hover {
    cursor: pointer;
  }
`;

const DeleteIcon = styled.button`
  background-image: url(${Trash});
  background-repeat: no-repeat;
  background-color: ${(props) => props.theme.background};
  border: none;
  height: 25px;
  width: 25px;
  margin-left: 16px;
  margin-top: 33px;
  &:hover {
    cursor: pointer;
  }
`;

const AddContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export default WebAdd;
