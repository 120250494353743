import { useQuery } from "@tanstack/react-query";
import { apiFactory } from "../utils/api";
import useAuth from "../auth/useAuth";

export const useGetApplication = (email, existingApp) => {
  const { getToken } = useAuth();

  const getApplication = async () => {
    const token = await getToken();
    const api = apiFactory(token);
    return api.business.getApplication(encodeURIComponent(email));
  };
  return useQuery(["get_application", email], getApplication, {
    enabled: existingApp && email !== "",
    refetchInterval: false,
    refetchOnWindowFocus: false,
    retry: 1,
  });
};
