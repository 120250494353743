import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import SmoothCollapse from "react-smooth-collapse";
import { Header, ActionRow, Next, ErrorText } from "../styles/global";
import { useGetApplications } from "../hooks/useGetApplications";
import { useGetUser } from "../hooks/useGetUser";
import { useGetChatbotQuery } from "../hooks"
import { useStore } from "../utils/store";
import { CoverageType, StyledDropdown, StyledInput } from '../components';

import RepsIcon from "../images/reps.png";
import CyberIcon from "../images/cyber.png";
import BondsIcon from "../images/bonds.png";
import OfficersIcon from "../images/officers.png";
import ErrorsIcon from "../images/errors.png";
import Arrow from "../images/arrow.svg";
import Edit from "../images/edit.svg";
import LiabilitiesIcon from "../images/liabilities.png";
import { lobLogic } from "../utils/lobLogic";
import { listOfCoverage } from "../utils/coverageLogic";
import useAuth from "../auth/useAuth";
import { coverage } from "../utils/enums";
import { useUpdateLob } from "../hooks/useUpdateLob";
import UpArrow from "../images/uparrow.svg";
import DownArrow from "../images/downarrow.svg";
import { searchCarrierOptions, searchLobOptions } from '../data';


const validationSchema = z.object({
  lineOfBusiness: z
    .string()
    .array()
    .min(1, { message: "Coverage type is required" })
    .or(z.string().nonempty({ message: "Coverage type is required" })),
});

const Binder = () => {
  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      lineOfBusiness: [],
    },
  });

  const [tempLob, setTempLob] = useState([]);
  const [otherCoverages, setOtherCoverages] = useState([]);
  const [coverages, setCoverages] = useState([]);
  const [enableSearch, setEnableSearch] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const searchEmail = useStore((state) => state.searchEmail);
  const chatQuery = useStore((state) => state.chatQuery);
  const updateSearchEmail = useStore((state) => state.updateSearchEmail);
  const updateChatQuery = useStore((state) => state.updateChatQuery);
  const [searchCarrier, setSearchCarrier] = useState({});
  const [searchLob, setSearchLob] = useState({});
  const [searchBarResult, setSearchBarResult] = useState('');
  const updateStep = useStore((state) => state.updateStep);
  const start = useStore((state) => state.start);
  const updateStart = useStore((state) => state.updateStart);
  const updateForm = useStore((state) => state.updateForm);
  const form = useStore((state) => state.form);
  const updateQuoted = useStore((state) => state.updateQuoted);
  const navigate = useNavigate();
  const { email, getAdmin } = useAuth();
  const admin = getAdmin();
  const mutation = useUpdateLob(email);
  const getChatbotQuery = useGetChatbotQuery();

  const updateTempLob = (payload, checked) => {
    if (checked) {
      return setTempLob([...tempLob, payload]);
    } else {
      return setTempLob(tempLob.filter((item) => item !== payload) || []);
    }
  };

  const handleChange = (e) => {
    setEnableSearch(false);
    updateSearchEmail(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const handleSearch = () => {
    if (searchEmail !== "") {
      updateForm(null);
      updateSearchEmail(searchEmail);
      setEnableSearch(true);
    }
  };
  const handleChatChange = (e) => {
	updateChatQuery(e.target.value);
};

const handleChatKeyDown = (e) => {
	if (e.key === 'Enter') {
		handleChatQuery();
	}
};


const handleChatQuery = () => {
	if (chatQuery !== '') {
		updateForm(null);
		updateChatQuery(chatQuery);
		const data = {
			query: chatQuery,
			lob: searchLob.value,
			carrier: searchCarrier.value,
		};
		const chatPayload = JSON.stringify(data);
		getChatbotQuery.mutate(chatPayload, {
			onSuccess: (res) => {
				if (res?.response) {
					console.log('success', res.response);
					setSearchBarResult(res.response);
				}
			},
			onError: (err) => {
				console.log(err);
			},
		});
	}
};


  const handleClick = () => {
    if (Object.keys(errors).length !== 0) return;
    updateStep(1);
    const payload = {
      lineOfBusiness: tempLob,
    };
    mutation.mutate(payload, {
      onSuccess: () => {
        navigate(lobLogic(tempLob));
        setTempLob([]);
      },
      onError: (error) => {
        console.log(error);
      },
    });

  };

  const { data: applicationsData, isInitialLoading } = useGetApplications(
    email,
    !admin
  );
  const { data: adminApplications } = useGetApplications(
    searchEmail,
    enableSearch
  );

  const { data: userData, isInitialLoading: userDataLoading } =
    useGetUser(email);

  const externalApps = start.lineOfBusiness.filter(
    (lob) => lob !== coverage.CYBER
  );

  useEffect(() => {
    if (userData === undefined && !userDataLoading && !admin) {
      navigate("/getstarted");
    }
  }, [userData, userDataLoading, applicationsData]);

  useEffect(() => {
    if (userData) {
      updateStart({
        firstName: userData.firstName,
        lastName: userData.lastName,
        email: userData.email,
        industry: userData.industry,
        hasExternalFunding: userData.hasExternalFunding,
        hasCustomers: userData.hasCustomers,
        hasEmployees: userData.hasEmployees,
        company: userData.company,
        lineOfBusiness: userData.lineOfBusiness,
      });
      const accountInfo = {
        contactFirstName: userData.firstName,
        contactLastName: userData.lastName,
        contactEmail: userData.email,
        organizationName: userData.company,
      };
      updateForm({ ...form, ...accountInfo });
    }
  }, [userData, updateStart]);

  useEffect(() => {
    if (applicationsData && !isInitialLoading && applicationsData.apps) {
      applicationsData.apps.map((app) => {
        if (app.status === "QUOTED") {
          updateQuoted(app);
        }
      });
    }
  }, [applicationsData, isInitialLoading]);

  const appStatusDisplay = (status) => {
    switch (status) {
      case "QUOTED":
        return <QuotesReady>Quotes Ready</QuotesReady>;
      case "SUBMITTED_ERROR":
      case "NEED_MORE_INFO":
      case "IN_REVIEW":
        return <AppStatus>In Review</AppStatus>;
      case "SUBMITTED":
        return <AppStatus>Retrieving quotes</AppStatus>;
      case "READY_TO_SUBMIT":
        return <AppStatus>Retrieving quotes</AppStatus>;
      case "READY_TO_RESUBMIT":
        return <AppStatus>Retrieving quotes</AppStatus>;
      case "ERROR":
        return <AppStatus>In Review</AppStatus>;
      case "SAVED":
      case "INCOMPLETE":
        return <AppStatus>Incomplete</AppStatus>;
      case "REQUESTED":
        return <AppStatus>Requested</AppStatus>;
      default:
        return;
    }
  };

  const appTypeDisplay = (type) => {
    switch (type) {
      case coverage.CYBER:
        return <AppType>Cyber</AppType>;
      case coverage.ERRORSANDOMISSIONS:
        return <AppType>Errors & Omissions</AppType>;
      case coverage.DIRECTORSANDOFFICERS:
        return <AppType>Directors & Officers</AppType>;
      case coverage.EMPLOYMENTPRACTICESLIABILITY:
        return <AppType>Employment Practices Liability</AppType>;
      case coverage.REPSANDWARRANTY:
        return <AppType>Reps & Warranty</AppType>;
      case coverage.BONDS:
        return <AppType>Bonds</AppType>;
      default:
        return;
    }
  };

  const coverageTypes = () => {
    if (admin) {
      return "Other";
    } else if (start.industry) {
      return start.industry;
    } else {
      return "Other";
    }
  };

  useEffect(() => {
    const filteredCoverages = () => {
      const fundingIndex = listOfCoverage(coverageTypes()).indexOf(
        coverage.DIRECTORSANDOFFICERS
      );
      const employeeIndex = listOfCoverage(coverageTypes()).indexOf(
        coverage.EMPLOYMENTPRACTICESLIABILITY
      );
      const customerIndex = listOfCoverage(coverageTypes()).indexOf(
        coverage.ERRORSANDOMISSIONS
      );

      const filteredArray = listOfCoverage(coverageTypes());
      if (fundingIndex !== -1 && start.hasExternalFunding === false) {
        filteredArray.splice(fundingIndex, 1);
      }
      if (employeeIndex !== -1 && start.hasEmployees === false) {
        filteredArray.splice(employeeIndex, 1);
      }
      if (customerIndex !== -1 && start.hasCustomers === false) {
        filteredArray.splice(customerIndex, 1);
      }
      return filteredArray;
    };

    const finalCoverages = filteredCoverages().filter(
      (lob) => !start.lineOfBusiness.includes(lob)
    );

    if (applicationsData && applicationsData.apps.length > 0) {
      applicationsData.apps.map((app) => {
        const lobIndex = finalCoverages.indexOf(app.type);
        if (lobIndex > -1) {
          finalCoverages.splice(lobIndex, 1);
        }
      });
      setCoverages(finalCoverages);
      const newOtherCoverages = listOfCoverage("Other").filter(
        (lob) => !finalCoverages.includes(lob)
      );
      applicationsData.apps.map((app) => {
        const lobIndex = newOtherCoverages.indexOf(app.type);
        if (lobIndex > -1) {
          newOtherCoverages.splice(lobIndex, 1);
        }
      });
      setOtherCoverages(
        newOtherCoverages.filter((lob) => !start.lineOfBusiness.includes(lob))
      );
    } else {
      setCoverages(
        filteredCoverages().filter((lob) => !start.lineOfBusiness.includes(lob))
      );
      const newOtherCoverages = listOfCoverage("Other")
        .filter((lob) => !filteredCoverages().includes(lob))
        .filter((lob) => !start.lineOfBusiness.includes(lob));
      setOtherCoverages(newOtherCoverages);
    }
  }, [applicationsData, start]);

  const displayIcon = (app) => {
    switch (app.status) {
      case "SUBMITTED":
      case "READY_TO_SUBMIT":
      case "IN_REVIEW":
        if (admin) {
          return (
              <Icon
                src={Edit}
                alt="edit icon"
                onClick={() => navigate(`/application/${app.id}`)}
              />
          );
        };  
      case "READY_TO_RESUBMIT":
        return "";
      case "INCOMPLETE":
      case "SAVED":
        return (
          <Icon
            src={Edit}
            alt="edit icon"
            onClick={() => navigate(`/application/${app.id}`)}
          />
        );
      case "QUOTED":
        return (
          <>
            <Icon
              src={Edit}
              alt="edit icon"
              onClick={() => navigate(`/application/${app.id}`)}
            />
            <Icon
              src={Arrow}
              alt="arrow icon"
              onClick={() => navigate("/quotes")}
            />
          </>
        );
      default:
        return "";
    }
  };

  const displayApplications = (appData) => {
    return (
      <AppContainer>
        {appData &&
          !isInitialLoading &&
          (appData.apps.length > 0 || externalApps.length > 0) && (
            <>
              <AppHeader>My Applications</AppHeader>
              <HeaderRow>
                <Heading>Coverage Type</Heading>
                <Heading>Status</Heading>
                <Heading></Heading>
              </HeaderRow>
              {appData.apps.length > 0 &&
                appData.apps.map((app) => {
                  return (
                    <AppRow key={app.id}>
                      <AppItem>{appTypeDisplay(app.type)}</AppItem>
                      <AppItem>{appStatusDisplay(app.status)}</AppItem>
                      <ActionIcon>{displayIcon(app)}</ActionIcon>
                    </AppRow>
                  );
                })}
              {externalApps.length > 0 &&
                externalApps.map((app, i) => {
                  return (
                    <AppRow key={i}>
                      <AppItem>{appTypeDisplay(app)}</AppItem>
                      <AppItem>{appStatusDisplay("REQUESTED")}</AppItem>
                      <ActionIcon>{displayIcon(app)}</ActionIcon>
                    </AppRow>
                  );
                })}
            </>
          )}
      </AppContainer>
    );
  };

  return (
    <>
		  {admin && (
			  <>
				  <AppHeader>Search Applications</AppHeader>
				  <StyledInput
					  name="searchApps"
					  placeholder="e.g. johnsmith@gmail.com"
					  value={searchEmail}
					  onChange={handleChange}
					  type="text"
					  onKeyDown={handleKeyDown}
				  />
				  <SearchButton onClick={handleSearch}>Search</SearchButton>

				  <AppHeader>Ask Bob</AppHeader>
				  <StyledInput
					  name='chatBot'
					  placeholder='Ask about a policy...'
					  value={chatQuery}
					  onChange={handleChatChange}
					  type='text'
					  onKeyDown={handleChatKeyDown}
				  />
				  <DropdownFlex>
					  <StyledDropdown
						  name='Carrier Select'
						  placeholder='Carrier'
						  options={searchCarrierOptions}
						  onChange={(val) => setSearchCarrier(val)}
						  className='searchDropdown'
					  />
					  <StyledDropdown
						  name='Line of Business Select'
						  placeholder='Line of Business'
						  options={searchLobOptions}
						  onChange={(val) => setSearchLob(val)}
						  className='searchDropdown'
					  />
				  </DropdownFlex>

				  <SearchButton onClick={handleChatQuery}>Search</SearchButton>
				  {searchBarResult && <SearchResult>{searchBarResult}</SearchResult>}


			  </>
      )}
      {admin
        ? displayApplications(adminApplications)
        : displayApplications(applicationsData)}
      {(userData || admin) && (
        <>
          {coverages.length > 0 && (
            <>
              <StyledHeader>Coverage Recommendations</StyledHeader>
              <RecommendText>
                Based on what we know about your business, we recommend these
                coverages:
              </RecommendText>
              <CoverageContainer>
                {coverages.map((c) => {
                  if (c === coverage.CYBER) {
                    return (
                      <CoverageType
                        key="cyber"
                        name="lineOfBusiness"
                        lob={c}
                        control={control}
                        label="Cyber"
                        iconSrc={CyberIcon}
                        register={register}
                        updateTempLob={updateTempLob}
                        tooltip="Cyber Insurance protects an organization from exposures related to its use of technology and data. Cyber exposures typically arise from data breaches, extortion, and deceptive funds theft incidents caused by criminal activity or human error. All organizations utilize technology in the delivery of their products or services and depend on technology for a wide range of functions such as communication, payments, and data storage."
                      />
                    );
                  }
                  if (c === coverage.ERRORSANDOMISSIONS) {
                    return (
                      <CoverageType
                        key="errorsAndOmissions"
                        name="lineOfBusiness"
                        lob={c}
                        control={control}
                        label="Errors & Omissions"
                        iconSrc={ErrorsIcon}
                        register={register}
                        updateTempLob={updateTempLob}
                        tooltip="Errors & Omissions (E&O) Insurance is designed to protect professionals and professional organizations from financial losses due to a claim or a lawsuit alleging professional negligence. E&O Insurance, sometimes referred to as Professional Liability Insurance or Malpractice, is a critical coverage for your organization, but not all E&O Insurance policies are the same."
                      />
                    );
                  }
                  if (c === coverage.DIRECTORSANDOFFICERS) {
                    return (
                      <CoverageType
                        key="directorsAndOfficers"
                        name="lineOfBusiness"
                        lob={c}
                        control={control}
                        label="Directors & Officers"
                        iconSrc={OfficersIcon}
                        register={register}
                        updateTempLob={updateTempLob}
                        tooltip="Sometimes called Management Liability, Directors and Officers (D&O) is designed to protect an organization’s leaders, and the organization itself, from financial losses related to their management decisions."
                      />
                    );
                  }
                  if (c === coverage.EMPLOYMENTPRACTICESLIABILITY) {
                    return (
                      <CoverageType
                        key="liability"
                        name="lineOfBusiness"
                        lob={c}
                        control={control}
                        label="Employment Practices Liability"
                        iconSrc={LiabilitiesIcon}
                        register={register}
                        updateTempLob={updateTempLob}
                        tooltip="Employment Practices Liability Insurance (EPL) protects employers from a variety of employment-related lawsuits and claims, and from EEOC and related actions. Some of the most common claims employees (and former employees) make against companies for wrongful termination, discrimination, and harassment. EPL can provide coverage for both damages and defense costs associated with a claim.  EPL can be purchased as a standalone policy or included as a coverage part in a D&O Insurance policy."
                      />
                    );
                  }
                  if (c === coverage.REPSANDWARRANTY) {
                    return (
                      <CoverageType
                        key="repsAndWarranty"
                        name="lineOfBusiness"
                        lob={c}
                        control={control}
                        label="Reps & Warranty"
                        iconSrc={RepsIcon}
                        register={register}
                        updateTempLob={updateTempLob}
                        tooltip="Reps & Warranty Insurance (R&W), also called Transactional Liability Insurance, provides financial protection in the purchase or sale of a business. R&W Insurance protects sellers or buyers of a business against financial loss due to unintentional and unknown breaches of representation and warranty provisions in a purchase/sale agreement. Both buyers and sellers can benefit from R&W Insurance, and its use has been increasing dramatically in all types of private transactions, including smaller transaction sizes."
                      />
                    );
                  }
                  if (c === coverage.BONDS) {
                    return (
                      <CoverageType
                        key="bonds"
                        name="lineOfBusiness"
                        lob={c}
                        control={control}
                        label="Bonds"
                        iconSrc={BondsIcon}
                        register={register}
                        updateTempLob={updateTempLob}
                        tooltip="
Bonds are a form of guarantee that responds based on performance and are quite different from traditional insurance. Unlike a liability or property insurance policy, a bond does not protect the buyer of the bond. The bond protects a third party, such as a governmental entity or an owner of a property under construction, from non-performance by the entity buying the bond."
                      />
                    );
                  }
                })}
              </CoverageContainer>
              {errors?.lineOfBusiness?.message && (
                <ErrorText>{errors.lineOfBusiness.message}</ErrorText>
              )}
            </>
          )}
          {otherCoverages.length > 0 && (
            <>
              <ExpandSection onClick={() => setIsExpanded(!isExpanded)}>
                <OtherText>Other coverage types we offer</OtherText>
                {isExpanded ? (
                  <ArrowIcon src={DownArrow} alt="down arrow icon" />
                ) : (
                  <ArrowIcon src={UpArrow} alt="up arrow icon" />
                )}
              </ExpandSection>
              <SmoothCollapse expanded={isExpanded}>
                <CoverageContainer>
                  {otherCoverages.map((c) => {
                    if (c === coverage.CYBER) {
                      return (
                        <CoverageType
                          key="cyber"
                          name="lineOfBusiness"
                          lob={c}
                          control={control}
                          label="Cyber"
                          iconSrc={CyberIcon}
                          register={register}
                          updateTempLob={updateTempLob}
                          tooltip="Cyber Insurance protects an organization from exposures related to its use of technology and data. Cyber exposures typically arise from data breaches, extortion, and deceptive funds theft incidents caused by criminal activity or human error. All organizations utilize technology in the delivery of their products or services and depend on technology for a wide range of functions such as communication, payments, and data storage."
                        />
                      );
                    }
                    if (c === coverage.ERRORSANDOMISSIONS) {
                      return (
                        <CoverageType
                          key="errorsAndOmissions"
                          name="lineOfBusiness"
                          lob={c}
                          control={control}
                          label="Errors & Omissions"
                          iconSrc={ErrorsIcon}
                          register={register}
                          updateTempLob={updateTempLob}
                          tooltip="Errors & Omissions (E&O) Insurance is designed to protect professionals and professional organizations from financial losses due to a claim or a lawsuit alleging professional negligence. E&O Insurance, sometimes referred to as Professional Liability Insurance or Malpractice, is a critical coverage for your organization, but not all E&O Insurance policies are the same."
                        />
                      );
                    }
                    if (c === coverage.DIRECTORSANDOFFICERS) {
                      return (
                        <CoverageType
                          key="directorsAndOfficers"
                          name="lineOfBusiness"
                          lob={c}
                          control={control}
                          label="Directors & Officers"
                          iconSrc={OfficersIcon}
                          register={register}
                          updateTempLob={updateTempLob}
                          tooltip="Sometimes called Management Liability, Directors and Officers (D&O) is designed to protect an organization’s leaders, and the organization itself, from financial losses related to their management decisions."
                        />
                      );
                    }
                    if (c === coverage.EMPLOYMENTPRACTICESLIABILITY) {
                      return (
                        <CoverageType
                          key="liability"
                          name="lineOfBusiness"
                          lob={c}
                          control={control}
                          label="Employment Practices Liability"
                          iconSrc={LiabilitiesIcon}
                          register={register}
                          updateTempLob={updateTempLob}
                          tooltip="Employment Practices Liability Insurance (EPL) protects employers from a variety of employment-related lawsuits and claims, and from EEOC and related actions. Some of the most common claims employees (and former employees) make against companies for wrongful termination, discrimination, and harassment. EPL can provide coverage for both damages and defense costs associated with a claim.  EPL can be purchased as a standalone policy or included as a coverage part in a D&O Insurance policy."
                        />
                      );
                    }
                    if (c === coverage.REPSANDWARRANTY) {
                      return (
                        <CoverageType
                          key="repsAndWarranty"
                          name="lineOfBusiness"
                          lob={c}
                          control={control}
                          label="Reps & Warranty"
                          iconSrc={RepsIcon}
                          register={register}
                          updateTempLob={updateTempLob}
                          tooltip="Reps & Warranty Insurance (R&W), also called Transactional Liability Insurance, provides financial protection in the purchase or sale of a business. R&W Insurance protects sellers or buyers of a business against financial loss due to unintentional and unknown breaches of representation and warranty provisions in a purchase/sale agreement. Both buyers and sellers can benefit from R&W Insurance, and its use has been increasing dramatically in all types of private transactions, including smaller transaction sizes."
                        />
                      );
                    }
                    if (c === coverage.BONDS) {
                      return (
                        <CoverageType
                          key="bonds"
                          name="lineOfBusiness"
                          lob={c}
                          control={control}
                          label="Bonds"
                          iconSrc={BondsIcon}
                          register={register}
                          updateTempLob={updateTempLob}
                          tooltip="
Bonds are a form of guarantee that responds based on performance and are quite different from traditional insurance. Unlike a liability or property insurance policy, a bond does not protect the buyer of the bond. The bond protects a third party, such as a governmental entity or an owner of a property under construction, from non-performance by the entity buying the bond."
                        />
                      );
                    }
                  })}
                </CoverageContainer>
              </SmoothCollapse>
            </>
          )}
          <ActionRow>
            <Next onClick={handleSubmit(handleClick)}>Start Application</Next>
          </ActionRow>
        </>
      )}
    </>
  );
};

const AppContainer = styled.div`
  margin-bottom: 60px;
`;

const OtherText = styled.h4`
  font-size: 16px;
  color: ${(props) => props.theme.lighterblack};
  font-weight: 500;
  margin-top: 16px;
`;

const ArrowIcon = styled.img`
  margin-left: 10px;
  margin-top: -5px;
  width: 15px;
`;

const ExpandSection = styled.div`
  display: flex;
  flex-direction: row;
  color: ${(props) => props.theme.lightblack};
`;

const SearchButton = styled(Next)`
  padding: 10px 24px;
  font-size: 15px;
`;

const ActionIcon = styled.div`
  justify-content: end;
  display: flex;
  height: 43px;
`;

const AppItem = styled.div`
  display: flex;
`;

const Heading = styled.h4`
  font-weight: 400;
  padding-left: 5px;
  margin-bottom: 10px;
`;

const HeaderRow = styled.div`
  padding: 0 24px;
  max-width: 600px;
  display: grid;
  grid-template-columns: 3fr 3fr 1fr;
`;

const AppRow = styled.div`
  background-color: ${(props) => props.theme.white};
  box-shadow: 1px 3px 14px -6px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 0 24px;
  max-width: 600px;
  display: grid;
  grid-template-columns: 3fr 3fr 1fr;
  margin-bottom: 12px;
`;

const StyledHeader = styled(Header)`
  margin-bottom: 0;
  font-weight: 700;
`;

const AppHeader = styled(Header)`
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 700;
`;

const Icon = styled.img`
  margin-top: 24px;
  margin-left: 20px;

  &:hover {
    cursor: pointer;
  }
`;

const SearchResult = styled.div`
	margin: 12px 0 24px 0;
	color: #3f8aba;
`;

const QuotesReady = styled.p`
  background-color: ${(props) => props.theme.ready};
  padding: 8px;
  border-radius: 8px;
  text-transform: capitalize;
`;

const AppStatus = styled.p`
  background-color: ${(props) => props.theme.pending};
  padding: 8px;
  border-radius: 8px;
  text-transform: capitalize;
`;

const AppType = styled.p`
  padding: 8px;
`;

const RecommendText = styled.h4`
  font-size: 18px;
  color: ${(props) => props.theme.offblack};
  font-weight: 500;
  margin-top: 10px;
`;

const DropdownFlex = styled.div`
	display: flex;
	gap: 24px;
	margin-top: 24px;
`;

// const NoApps = styled.h4`
//   font-size: 18px;
//   color: ${(props) => props.theme.offblack};
//   font-weight: 500;
// `;

const CoverageContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;
  max-width: 550px;
  margin-bottom: ${(props) => (props.haserror ? "10px" : "30px")};
  position: relative;
`;

export default Binder;
