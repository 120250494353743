import React from "react";
import styled from "styled-components";
import { useController } from "react-hook-form";
import { useStore } from "../utils/store";

const CoverageType = ({
  label,
  register,
  name,
  control,
  iconSrc,
  lob,
  tooltip,
  hasError,
  updateTempLob,
}) => {
  const { field } = useController({
    name,
    control,
  });

  const updateCoverage = (e) => {
    updateTempLob(lob, e.target.checked);
    field.onChange(lob);
  };

  return (
    <Container>
      <TooltipCard>
        <Wrapper>
          <Row>
            <StyledCoverage
              {...register(name)}
              onChange={(e) => updateCoverage(e)}
              type="checkbox"
              value={field.value}
              id={label}
            />
          </Row>
          <Label htmlFor={label}>
            <Icon src={iconSrc} alt="" />
            {label}
          </Label>
        </Wrapper>
        {tooltip && (
          <TooltipBox haserror={hasError}>
            <Line />
            <TooltipText>{tooltip}</TooltipText>
          </TooltipBox>
        )}
      </TooltipCard>
    </Container>
  );
};

const Line = styled.div`
  width: 40px;
  border-bottom: ${(props) => `3px dotted ${props.theme.toolTipBackground}`};
  margin: ${(props) => (props.haserror ? "45px auto auto auto" : "auto")};
`;

const Wrapper = styled.div`
  padding: 0 10px 0 10px;
  box-shadow: 1px 3px 14px -6px rgba(0, 0, 0, 0.25);
  max-width: 180px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.white};
  border-radius: 8px;

  &:hover {
    cursor: pointer;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

const TooltipBox = styled.div`
  position: absolute;
  display: flex;
  visibility: hidden;
  left: 550;
`;

const TooltipCard = styled.div`
  display: flex;

  & ${Wrapper}:hover + ${TooltipBox} {
    visibility: visible;
  }
`;

const TooltipText = styled.div`
  margin: auto;
  color: #4e5357;
  font-size: 14px;
  border-radius: 8px;
  background-color: #c4dff1;
  width: 300px;
  padding: 12px;
`;

const Label = styled.label`
  padding: 0 16px 8px 16px;
  color: ${(props) => props.theme.offblack};
  font-size: 16px;
  weight: 500;
  overflow-wrap: break-word;
  max-width: 350px;
  text-align: center;

  &:hover {
    cursor: pointer;
  }
`;

const StyledCoverage = styled.input`
  margin-top: 10px;

  &:hover {
    cursor: pointer;
  }
`;

const Icon = styled.img`
  width: 80px;
  margin: 8px 16px;
  padding-bottom: 10px;

  &:hover {
    cursor: pointer;
  }
`;

const Row = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-wrap: wrap;
  background-color: ${(props) => props.theme.offWhite};
  align-items: center;
  border-radius: 4px 4px 0 0;
  position: relative;

  &:hover {
    cursor: pointer;
  }
`;

export default CoverageType;
