import React from "react";
import styled from "styled-components";
import Pro from "../images/pro.svg";
import Con from "../images/con.svg";

export const buildProsCons = (list) => {
  let pros, cons;

  list.sort((a, b) => a.rank - b.rank);

  list.map((item) => {
    if (item.type === "PRO") {
      pros = (
        <>
          {pros}
          <Row>
            <Icon src={Pro} alt="plus symbol" />
            <span>{item.factText}</span>
          </Row>
        </>
      );
    } else if (item.type === "CON") {
      cons = (
        <>
          {cons}
          <Row>
            <Icon src={Con} alt="minus symbol" />
            <span>{item.factText}</span>
          </Row>
        </>
      );
    }
  });

  return (
    <Container>
      {pros}
      {cons}
    </Container>
  );
};

const Container = styled.div``;

const Icon = styled.img`
  padding-right: 10px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin: 12px 0;
`;
