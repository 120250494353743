import { useQuery } from "@tanstack/react-query";
import { apiFactory } from "../utils/api";
import useAuth from "../auth/useAuth";

export const useGetApplications = (email, enable) => {
  const { getToken } = useAuth();

  const getApplications = async () => {
    const token = await getToken();
    const api = apiFactory(token);
    return api.business.getApplications(encodeURIComponent(email));
  };
  return useQuery(["get_applications", email], getApplications, {
    enabled: enable,
    refetchInterval: data => (poll(data) ? 10000 : false),
    refetchOnWindowFocus: false,
    retry: 1,
  });

  function poll(applicationsData)  {  
    if (applicationsData  && applicationsData.apps) {   
      applicationsData.apps.map((app) => {
        if (app.status === "QUOTED") {
          //console.log("Stop polling, quotes found!");
          return false;
        } else if (app.status === "SUBMITTED" || app.status=== "READY_TO_SUBMIT")  {
          console.log("Keep polling, no quotes found!, submitted");
          return true;
        } else {
          console.log("Stop polling, app in staus!:"+app.status);
          return true; 
        }
      });
    } else {
     console.log("Stop polling, user doesnt have cyber app!");
     return true;
    }
    return true;
  };
}
