export const searchLobOptions = [
	{ value: 'ERRORSANDOMMISIONS', label: 'Errors and Ommisions' },
	{ value: 'CYBER', label: 'Cyber' },
	{
		value: 'EMPLOYMENTPRACTICESLIABILITY',
		label: 'Employment Practices and Liabilities',
	},
	{ value: 'DIRECTORSANDOFFICERS', label: 'Directors and Officers' },
	{ value: 'BONDS', label: 'Bonds' },
	{ value: 'REPSANDWARRANTS', label: 'Reps and Warrants' },
];

export const searchCarrierOptions = [
	{ value: 'CFC_ES', label: 'CFC ES' },
	{ value: 'Coalition_AD', label: 'Coalition Admitted' },
	{ value: 'Coalition_ES', label: 'Coalition ES' },
	{ value: 'AtBay_AD', label: 'AtBay Admitted' },
	{ value: 'AtBay_ES', label: 'AtBay ES' },
	{ value: 'TMHCC_ES', label: 'TMHCC ES' },
];
