import { useQuery } from "@tanstack/react-query";
import { apiFactory } from "../utils/api";
import useAuth from "../auth/useAuth";

export const useGetQuotes = (email, admin) => {
  const { getToken } = useAuth();

  const getQuotes = async () => {
    const token = await getToken();
    const api = apiFactory(token);
    if (email) {
      return api.business.getQuotes(encodeURIComponent(email));
    } else {
      return null;
    }
  };
  return useQuery(["get_quotes", email], getQuotes, {
    enabled: admin,
    refetchInterval: false,
    refetchOnWindowFocus: false,
    retry: 1,
  });
};
