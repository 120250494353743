import React, { useEffect } from "react";
import styled from "styled-components";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import AppRoutes from "./layout/AppRoutes";
import { ThemeProvider } from "styled-components";
import { theme } from "./styles/theme";
import NavBar from "./layout/NavBar";
import Sidebar from "./layout/Sidebar";
import { PageWrapper, ContentWrapper } from "./styles/global";
import useAuth from "./auth/useAuth";
import { useStore } from "./utils/store";
import { mobile } from "./styles/responsive";

const queryClient = new QueryClient();

const App = () => {
  const { isAuthenticated, getAdmin } = useAuth();
  const admin = getAdmin();
  const updateAdmin = useStore((state) => state.updateAdmin);

  useEffect(() => {
    if (isAuthenticated) {
      updateAdmin(admin);
    }
  }, [isAuthenticated, admin, updateAdmin]);

  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <PageWrapper>
            <Sidebar />
            <Column>
              <NavBar />
              <ContentWrapper>
                <AppRoutes />
              </ContentWrapper>
            </Column>
          </PageWrapper>
        </ThemeProvider>
      </QueryClientProvider>
    </div>
  );
};

const Column = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.background};
  overflow-y: auto;
  flex: 4;
`;

export default App;
