import React, { useState } from "react";
import styled, { css } from "styled-components";
import { useController } from "react-hook-form";
import { Line, StyledTooltip, ErrorText } from "../styles/global";

export const input_styles = css`
  width: 350px;
  height: 100px;
  background-color: white;
  width: 100%;
  border-radius: 8px;
  padding: 12px 20px;
  box-shadow: 1px 3px 14px -6px rgba(0, 0, 0, 0.25);
  border: ${(props) =>
    props.haserror
      ? `1px solid ${props.theme.red}`
      : `1px solid ${props.theme.white}`};
  font-size: 14px;
  color: ${(props) => props.theme.offblack};
  width: 430px;
  &:focus {
    border: ${(props) =>
      props.haserror
        ? `1px solid ${props.theme.red}`
        : `1px solid ${props.theme.white}`};   
    outline: none;
  }

  ::placeholder {
      color: ${(props) => props.theme.placeholder};
`;

export const StyledInput = styled.textarea`
  ${input_styles}
`;

const Textarea = ({
  label,
  register,
  name,
  placeholder,
  maxLength,
  pattern,
  hasError,
  control,
  formatter,
  disabled,
  tooltip,
  small,
}) => {
  const { field } = useController({
    name,
    control,
  });

  const [showTooltip, setShowTooltip] = useState(false);

  const handleFocus = () => {
    setShowTooltip(true);
  };

  const handleBlur = () => {
    setShowTooltip(false);
  };

  return (
    <Container>
      <Wrapper haserror={hasError}>
        <Label htmlFor={label}>{label}</Label>
        <StyledInput
          name={name}
          placeholder={placeholder}
          maxLength={maxLength}
          pattern={pattern}
          haserror={hasError}
          onFocus={handleFocus}
          onBlur={handleBlur}
          disabled={disabled}
          onChange={(value) => field.onChange(value)}
        />
        {hasError && <ErrorText>{hasError}</ErrorText>}
      </Wrapper>
      {showTooltip && tooltip && (
        <>
          <Line haserror={hasError} />
          <StyledTooltip small={small} haserror={hasError}>
            {tooltip}
          </StyledTooltip>
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

const Wrapper = styled.div`
  margin-bottom: ${(props) => (props.haserror ? "10px" : "30px")};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  padding: 0 0px 8px 0px;
  color: ${(props) => props.theme.offblack};
  font-size: 16px;
  weight: 500;
  overflow-wrap: break-word;
  max-width: 350px;
  text-align: left;
`;

export default Textarea;
