import { useMutation } from '@tanstack/react-query';

import useAuth from '../auth/useAuth';
import { apiFactory } from '../utils/api';

const useGetChatbotQuery = () => {
	const { getToken } = useAuth();

	const getChatbotQuery = async (payload) => {
		const token = await getToken();
		const api = apiFactory(token);
		return api.ai.getChatbotQuery(payload);
	};
	return useMutation(getChatbotQuery);
};

export default useGetChatbotQuery;