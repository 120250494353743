import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Autocomplete from "react-google-autocomplete";
import { input_styles } from "./Input";
import { useStore } from "../utils/store";
import { useController } from "react-hook-form";
import { Line, StyledTooltip } from "../styles/global";

const Address = React.forwardRef(
  ({ hasError, control, register, name, tooltip }, ref) => {
    const {
      field,
      fieldState: { error },
    } = useController({
      name,
      control,
    });

    const [displayAddress, setDisplayAddress] = useState("");
    const [showTooltip, setShowTooltip] = useState(false);

    const updateFullAddress = useStore((state) => state.updateFullAddress);
    const fullAddress = useStore((state) => state.fullAddress);
    const form = useStore((state) => state.form);

    useEffect(() => {
      if (
        form.organizationAddress.street !== "" &&
        form.organizationAddress.street !== null
      ) {
        setDisplayAddress(fullAddress);
      } else {
        setDisplayAddress("");
      }
    }, [form?.organizationAddress?.street, fullAddress]);

    const updateAddress = (place) => {
      updateFullAddress(place.formatted_address);
      const selectedAddress = place.address_components.reduce((acc, cv) => {
        acc[cv.types[0]] = cv.short_name;
        return acc;
      });
      const streetAddress = selectedAddress.route
        ? `${selectedAddress.long_name} ${selectedAddress.route}`
        : `${selectedAddress.long_name}`;

      const fullAddress = {
        street: streetAddress,
        city: selectedAddress.locality||selectedAddress.sublocality_level_1,
        state: selectedAddress.administrative_area_level_1,
        zipCode: selectedAddress.postal_code,
      };

      setDisplayAddress(place.formatted_address);
      setShowTooltip(false);
      field.onChange(fullAddress);
    };

    const handleFocus = () => {
      setShowTooltip(true);
    };

    const handleBlur = () => {
      setShowTooltip(false);
    };

    return (
      <Container>
        <Wrapper haserror={hasError}>
          <Label htmlFor="Business Address">Business Address</Label>
          <StyledAutocomplete
            {...register(name)}
            apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
            onPlaceSelected={(place) => {
              updateAddress(place);
            }}
            options={{
              types: ["address"],
              componentRestrictions: { country: "us" },
            }}
            onChange={(e) => setDisplayAddress(e.target.value)}
            ref={ref}
            haserror={hasError}
            value={displayAddress}
            onBlur={handleBlur}
            onFocus={handleFocus}
          />
        </Wrapper>
        {showTooltip && tooltip && (
          <>
            <Line haserror={error} />
            <StyledTooltip haserror={error}>{tooltip}</StyledTooltip>
          </>
        )}
      </Container>
    );
  }
);

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledAutocomplete = styled(Autocomplete)`
  ${input_styles}
`;

const Wrapper = styled.div`
  margin-bottom: ${(props) => (props.haserror ? "10px" : "30px")};
  max-width: 450px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  padding: 0 16px 8px 16px;
  color: ${(props) => props.theme.offblack};
  font-size: 16px;
  weight: 500;
  overflow-wrap: break-word;
  max-width: 350px;
  text-align: left;
`;

export default Address;
