import { coverage } from "./enums";

export const listOfCoverage = (industry) => {
  switch (industry) {
    case "Consulting":
      return [
        coverage.ERRORSANDOMISSIONS,
        coverage.CYBER,
        coverage.EMPLOYMENTPRACTICESLIABILITY,
      ];
    case "Design":
      return [
        coverage.ERRORSANDOMISSIONS, // customers
        coverage.CYBER, // always
        coverage.EMPLOYMENTPRACTICESLIABILITY, // employees
        coverage.DIRECTORSANDOFFICERS, // funding
      ];
    case "Insurance":
      return [
        coverage.ERRORSANDOMISSIONS,
        coverage.CYBER,
        coverage.EMPLOYMENTPRACTICESLIABILITY,
        coverage.DIRECTORSANDOFFICERS,
      ];
    case "Non-Profit":
      return [
        coverage.ERRORSANDOMISSIONS,
        coverage.CYBER,
        coverage.EMPLOYMENTPRACTICESLIABILITY,
        coverage.DIRECTORSANDOFFICERS,
      ];
    case "Real Estate":
      return [
        coverage.ERRORSANDOMISSIONS,
        coverage.CYBER,
        coverage.EMPLOYMENTPRACTICESLIABILITY,
        coverage.DIRECTORSANDOFFICERS,
      ];
    case "Technology":
      return [coverage.ERRORSANDOMISSIONS, coverage.CYBER];
    case "Trustee":
      return [
        coverage.ERRORSANDOMISSIONS,
        coverage.CYBER,
        coverage.DIRECTORSANDOFFICERS,
      ];
    case "Other":
      return [
        coverage.ERRORSANDOMISSIONS,
        coverage.CYBER,
        coverage.EMPLOYMENTPRACTICESLIABILITY,
        coverage.DIRECTORSANDOFFICERS,
        coverage.BONDS,
        coverage.REPSANDWARRANTY,
      ];
    default:
      return [
        coverage.ERRORSANDOMISSIONS,
        coverage.CYBER,
        coverage.EMPLOYMENTPRACTICESLIABILITY,
        coverage.DIRECTORSANDOFFICERS,
        coverage.BONDS,
        coverage.REPSANDWARRANTY,
      ];
  }
};
